import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, timer } from 'rxjs';
import { BancoService } from 'src/app/services/banco.service';
import { CotacaoService } from 'src/app/services/cotacao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-cotacao-visualizar',
  templateUrl: './cotacao-visualizar.component.html',
  styleUrls: ['./cotacao-visualizar.component.scss']
})
export class CotacaoVisualizarComponent implements OnInit {

  @Input() public cotacao: any = {};

  public form: FormGroup;

  public bancoSearch?: string;
  public bancoSuggestions$?: Observable<any[]>;
  public bancoTypeaheadLoading: boolean = false;
  public bancoTypeaheadNoResults: boolean = false;

  public fields: { [index: string]: string } = {};
  public showErrors: boolean = false;

  constructor(
    private location: Location,
    private bancoService: BancoService,
    private cotacaoService: CotacaoService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder) {

      this.form = this.fb.group({
        bancoComercialId: ['', Validators.required],
        clienteAgencia: [, Validators.required],
        clienteContaCorrente: [, Validators.required],
        fechamentoId: [, Validators.required],
        //bancoSearch: [, Validators.required],
        clienteId: [, Validators.required],
        fechamentoCompleto: false,
      });

  }

  ngOnInit(): void {

    //debugger;
    const id = this.route.snapshot.params['id'];
    console.log(id);
    this.form.controls["fechamentoId"].setValue(id);

    this.cotacaoService.obterCotacaoFechamento(id).subscribe((cotacao: any) => {
      this.cotacao = cotacao;
      this.form.controls["clienteAgencia"].setValue(this.cotacao.clienteDadoBancario.agencia);
      this.form.controls["clienteContaCorrente"].setValue(this.cotacao.clienteDadoBancario.contaCorrente);
      // Verificamos se tem uma conta preferencial cadastrada para o cliente receber
      // Se tiver já indicamos ...
      if (this.cotacao.clienteDadoBancario.bancoComercial != null)
      {
        this.form.controls["bancoComercialId"].setValue(this.cotacao.clienteDadoBancario.bancoComercial.id);
        this.bancoSearch = this.cotacao.clienteDadoBancario.bancoComercial.nome;

      }
        
      this.form.controls["clienteId"].setValue(this.cotacao.clienteId);

      // Verificamos se este fechamento já tem uma conta associada. Se tiver, não pede os dados bancários
      let dadoBancario = this.cotacao.clienteDadoBancarioId;
      if (dadoBancario > 0)
      {
        this.form.controls["fechamentoCompleto"].setValue(true);
      }

      if(this.cotacao.tipoTransacao == 0)
      {
        this.form.controls["fechamentoCompleto"].setValue(true);
      }
      
    });

    this.carregarBancos();
  }

  carregarBancos() {
    this.bancoSuggestions$ = Observable.create((observer: any) => {
      this.bancoService.obterBancosComerciaisPorPhrase(this.bancoSearch).subscribe((result: any) => {
        observer.next(result);
      })
    });
  }

  bancoChangeTypeaheadLoading(e: boolean): void {
    this.bancoTypeaheadLoading = e;
  }

  bancoTypeaheadOnSelect(e: any): void {
    this.form.controls["bancoComercialId"].setValue(e.item.id);
  }

  bancoTypeaheadOnBlur(e: any): void {
    if (!e || !e.item) this.form.controls["bancoComercialId"].setValue('');
  }


  back() {
    this.location.back();
  }

  gravarAgencia() {
    //console.log(this.form);
    if (this.form.invalid) {
      this.showErrors = true;
      console.log("Erro no form");
      return;
    };

    this.cotacaoService.salvardadosbancarios(this.form.value).subscribe((data: any) => {
      this.form.controls["fechamentoCompleto"].setValue(true);
      //this.cotacao = data;
      //this.router.navigate(['/restrito/cotacao/' + this.cotacao.id]);
    });
    
  }

  enviarEmail()
  {
    this.cotacaoService.enviarEmail(this.route.snapshot.params['id']).subscribe((data: any) => {
      //this.form.controls["btnEnviarEmail"].disabled;
      //this.cotacao = data;
      //this.router.navigate(['/restrito/cotacao/' + this.cotacao.id]);
      this.router.navigate(['/restrito/cotacao/']);
    });
  }



}
