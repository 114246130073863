const { GetObjectCommand, S3Client } = require('@aws-sdk/client-s3');

const s3Client = new S3Client({
  region: 'sa-east-1',
  credentials: {
    accessKeyId: 'AKIAUWMK3OMQI4Z6UXYO',
    secretAccessKey: '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',
  },
});

// Correção
export const getFile = async (name: string) => {
  const command = new GetObjectCommand({
    Bucket: 'zebra-compliance-docs',
    Key: name,
  });

  try {
    const response = await s3Client.send(command);
    const str = await response.Body?.transformToByteArray();
    saveByteArray(name, str);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

function saveByteArray(reportName: string, byte: any) {
  var blob = new Blob([byte], { type: 'application/pdf' });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
}
