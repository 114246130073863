import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-esqueci-minha-senha-page',
  templateUrl: './esqueci-minha-senha-page.component.html',
  styleUrls: ['./esqueci-minha-senha-page.component.scss']
})
export class EsqueciMinhaSenhaPageComponent implements OnInit {

  public form: FormGroup;

  public fields: { [index: string]: string } = {};
  public showErrors: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private messageService: MessageService
  ) {

    this.form = this.fb.group({
      email: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.fields["email"] = "E-mail";
  }

  send() {
    if (this.form.invalid) {
      this.showErrors = true;
      return;
    };

    const data = {
      email: this.form.value.email
    }

    this.loginService.esqueciSenha(data).subscribe(() => {
      this.messageService.success('E-mail enviado com sucesso.')
      this.router.navigate(['']);
    });
  }

}