import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    public onAlterarPerfilEvent = new EventEmitter();
    constructor(private http: HttpClient) { }

    alterarSenha(data: any) {
        return this.http.post(environment.apiUrl + '/usuarios/change-password', data);
    }

    recuperarSenha(data: any) {
        return this.http.post(environment.apiUrl + '/usuarios/forgot-password-recover', data);
    }

    esqueciSenha(data: any) {
        return this.http.post(environment.apiUrl + '/usuarios/forgot-password', data);
    }
}
