export class MatrizRiscoParametro {

  constructor(
    tipoParametro: string = "",
    jsonPropertyDescricao: string = "",
    operadorLogicoId: number = 0,
    valor1: string = "",
    valor2: string = "",
    score: number = 0) {

      this.tipoParametro = tipoParametro;
      this.jsonPropertyDescricao = jsonPropertyDescricao;
      this.operadorLogicoId = operadorLogicoId;
      this.valor1 = valor1;
      this.valor2 = valor2;
      this.score = score;

    }

  id: number = 0;
  tipoParametro: string = "";
  jsonPropertyId: number = 0;
  jsonPropertyDescricao: string = "";
  tipoDado: string = "";
  operadorLogicoId: number = 0;
  operadorLogicoDescricao: string = "";
  valor1: string = "";
  valor2: string = "";
  score: number = 0;
}
