import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { CotacaoPageComponent } from './pages/cotacao-page/cotacao-page.component';
import { EsqueciMinhaSenhaPageComponent } from './pages/esqueci-minha-senha-page/esqueci-minha-senha-page.component';
import { RecuperarSenhaPageComponent } from './pages/recuperar-senha-page/recuperar-senha-page.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component'
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CotacaoComponent } from './components/cotacao/cotacao.component';
import { AlterarSenhaComponent } from './pages/alterar-senha/alterar-senha.component';
import { HttpErrorInterceptor } from './services/interceptors/http-error.interceptor';
import { MessageService } from './services/message.service';
import { Router } from '@angular/router';
import { AuthenticationInterceptor } from './services/interceptors/authentication.interceptor';
import { AlertModelComponent } from './components/alert-model/alert-model.component';
import { CotacaoVisualizarComponent } from './components/cotacao-visualizar/cotacao-visualizar.component';
import { CotacaoVisualizarPageComponent } from './pages/cotacao-visualizar-page/cotacao-visualizar-page.component';
import { OperacoesPageComponent } from './pages/operacoes-page/operacoes-page.component';
import { UploadArquivosComponent } from './components/upload-arquivos/upload-arquivos.component';
import { CotacoesManualPageComponent } from './pages/cotacao-manual-page/cotacao-manual-page.component';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { NotificarQuedaAltaPageComponent } from './pages/notificar-queda-alta-page/notificar-queda-alta-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { CotacoesPageComponent } from './pages/cotacoes-page/cotacoes-page.component';
import { CotacoesTempoRealPageComponent } from './pages/cotacao-tempo-real-page/cotacao-tempo-real-page.component';
import { ComplianceComponent } from './components/compliance/compliance.component';
import { FinanceiroComponent } from './components/financeiro/financeiro.component';
import { ParametrosComponent } from './components/parametros/parametros.component';
import {MatDialogModule} from '@angular/material/dialog';
import { DialogContentComponent } from './components/dialog-content/dialog-content.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import { DialogParametrosComponent } from './components/dialog-parametros/dialog-parametros.component';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';


registerLocaleData(ptBr);


//export const maskOoptions: Partial<null | IConfig> | (() => Partial<IConfig>) = null;
export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    CotacaoPageComponent,
    EsqueciMinhaSenhaPageComponent,
    RecuperarSenhaPageComponent,
    ConfirmModalComponent,
    HomePageComponent,
    NavbarComponent,
    CotacaoComponent,
    AlterarSenhaComponent,
    AlertModelComponent,
    CotacaoVisualizarComponent,
    CotacaoVisualizarPageComponent,
    OperacoesPageComponent,
    UploadArquivosComponent,
    NotificarQuedaAltaPageComponent,
    DashboardPageComponent,
    CotacoesPageComponent,
    CotacoesManualPageComponent,
    CotacoesTempoRealPageComponent,
    ComplianceComponent,
    FinanceiroComponent,
    ParametrosComponent,
    DialogContentComponent,
    DialogParametrosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    NgxMaskModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
    CurrencyMaskModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatTooltipModule,
  ],
  providers: [
    BsModalService,
    HttpClientModule,
    { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [Router, MessageService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
