import { Usuario } from '../models/usuario';
import jwt_decode from "jwt-decode";

export class LocalStorageUtils {


    public limparDadosLocais() {
        localStorage.removeItem('zebra.token');
    }

    public obterTokenUsuario(): any {
        return localStorage.getItem('zebra.token');
    }

    decodeJwt(): any {
        try {
            return jwt_decode(localStorage.getItem('zebra.token') || "");
        } catch (Error) {
            return null;
        }
    }


}