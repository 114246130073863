import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequireMatch } from './requireMatch';
import { Parameter } from 'src/app/models/parameter';
import { Observable, map, of, startWith } from 'rxjs';
import { MatrizRiscoService } from 'src/app/services/matriz-risco.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatrizRiscoParametro } from 'src/app/models/matriz-risco-parametro';

@Component({
  selector: 'app-dialog-parametros',
  templateUrl: './dialog-parametros.component.html',
  styleUrls: ['./dialog-parametros.component.scss'],
})
export class DialogParametrosComponent implements OnInit {

  @ViewChild('parametroInput', { static: true }) parametroInput?: ElementRef;
  nomeParametroFormControl = new FormControl('', [ Validators.required, this.isOptionValid.bind(this)]);
  tipoParametroFormControl = new FormControl('', [ Validators.required ]);
  operadorLogicoParametroFormControl = new FormControl('', [ Validators.required ]);
  scoreFormControl = new FormControl('', [ Validators.required ]);
  options: Parameter[] = [];
  filteredOptions?: Observable<Parameter[]>;
  tipoDadoTela: string = "";

  tipoParametro!: string;
  nomeParametro!: string;
  operadorLogico!: number;
  valor1!: string;
  valor2!: string;
  score!: number;

  parametro = new MatrizRiscoParametro();

  constructor(private matrizRiscoService: MatrizRiscoService) {}

  ngOnInit(): void {
    this.getParametroNome();

  }

  private _filter(value: string): Parameter[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.jsonPropertyDescricao.toLowerCase().includes(filterValue)
    );
  }

public validation(): boolean {
  if(this.nomeParametroFormControl.errors
    || this.tipoParametroFormControl.errors
    || this.operadorLogicoParametroFormControl.errors
    || this.scoreFormControl.errors){
    return true;
  }

  return false;
}

  getParametroNome() {
    this.matrizRiscoService
      .getallParametrosNome()
      .subscribe(
        (retorno: Parameter[]) => {
          this.options = retorno;
          this.filteredOptions =  this.nomeParametroFormControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );
        },
        () => {},
        () => {}
      )
      .add();
  }

isOptionValid(control: FormControl): { [key: string]: boolean } | null {
  if(this.options == undefined){
    return { 'incorrect': false };
  }
  const selectedOption = this.options.find(
    (option) => option.jsonPropertyDescricao === control.value
  );

  return selectedOption ? null : { 'incorrect': true };
}

onOptionSelected(selectedOption: string) {

  const selectedObject = this.options.find(option => option.jsonPropertyDescricao === selectedOption);

  if (selectedObject) {

    if(this.parametroInput){
      this.parametroInput.nativeElement.value = selectedObject.tipoDado;
      this.tipoDadoTela = selectedObject.tipoDado;
    }

    this.nomeParametroFormControl.setValue(selectedOption);

  }
}

public saveParametro(): void{

  if(this.validation() == false){

    this.parametro  = new MatrizRiscoParametro(
      this.tipoParametro,
      this.nomeParametro,
      this.operadorLogico,
      this.valor1,
      this.valor2,
      this.score
    );

    this.matrizRiscoService.post(this.parametro).subscribe(
      (response: string) => {
      },
      (error: any) => {
      },).add();
    }

  }

}
