<app-navbar></app-navbar>

<div class="container h-100">
  <div class="row justify-content-center align-items-center h-100">
    <div
      class="col col-sm-6 col-md-6 col-lg-6 col-xl-7 order-lg-1 order-xl-1 px-5"
    >
      <h1>Olá,</h1>
      <p class="mb-4">Entre com as suas credenciais e bom trabalho!</p>
      <form [formGroup]="form" (submit)="login()">
        <!--<validation-summary [form]="form" [fields]="fields" [showErrors]="showErrors"></validation-summary>-->
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            maxlength="255"
            autofocus
            type="email"
            class="form-control"
            id="email"
            placeholder="nome@example.com"
            formControlName="email"
          />
        </div>
        <div class="mb-3">
          <label for="senha" class="form-label">Senha</label>
          <input
            maxlength="100"
            type="password"
            class="form-control"
            id="senha"
            placeholder="Senha"
            formControlName="senha"
          />
        </div>
        <button class="w-100 btn btn-lg btn-dark" type="submit">Entrar</button>
        <p class="text-center mt-3">
          <a class="text-dark" [routerLink]="['/esqueci-minha-senha']"
            >Esqueci minha senha</a
          >
        </p>
      </form>
    </div>
    <div
      class="col text-center align-middle bg-sign-in d-none d-lg-flex d-xl-flex col-lg-6 col-xl-5 order-lg-2 order-xl-2"
    >
      <img src="../../../assets/min-logo.svg" alt="ZEBRA" />
    </div>
  </div>
</div>
