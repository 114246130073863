<div class="card mt-3 mb-3 shadow-sm">
    <div class="card-body">
        <h3>Cotações</h3>
    </div>
    <form [formGroup]="form">

        <div class="row">
            <div class="col col-md-4 col-sm-12">
                <input autofocus maxlength="10" formControlName="dataInicioFechamento" type="text"
                    class="form-control mb-2" placeholder="Buscar por data de fechamento (De:)"
                    onfocus="(this.type='date')" onblur="(this.type= this.value ? 'date' : 'text')" />
            </div>
            <div class="col col-md-4 col-sm-12">
                <input autofocus maxlength="10" formControlName="dataFimFechamento" type="text"
                    class="form-control mb-2" placeholder="Buscar por data de fechamento (Até:)"
                    onfocus="(this.type='date')" onblur="(this.type= this.value ? 'date' : 'text')" />
            </div>
            <div class="col  col-sm-12 col-md-4">
                <select formControlName="tipoTransacaoId" class="form-select mb-2" id="tipoTransacaoId">
                    <option value="">Buscar por tipo de transação</option>
                    <option *ngFor="let t of tiposDeTransacoes" [ngValue]="t.id">
                        {{ t.nome }}
                    </option>
                </select>
            </div>

            <div class="col col-md-8 col-sm-12">
                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="clienteSearch" [typeahead]="clienteSuggestions$"
                (typeaheadLoading)="clienteChangeTypeaheadLoading($event)"
                (typeaheadOnSelect)="clienteTypeaheadOnSelect($event)" (typeaheadOnBlur)="clienteTypeaheadOnBlur($event)"
                [typeaheadWaitMs]="300" [typeaheadOptionsLimit]="7" [typeaheadOptionField]="'nome'" placeholder="Buscar por cliente: Pesquise por nome, e-mail, cpnj ou cpf"
                class="form-control">
            </div>

            <div class="col col-sm-12 col-md-4 text-end">
                <button (click)="search()" class="btn btn-secondary"><i class="fa fa-search" aria-hidden="true"></i>
                    Buscar</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="cotacoes.length==0" class="alert alert-primary mt-3" role="alert">
    Nenhum registro encontrado
</div>
<div class="table-responsive">
    <table *ngIf="cotacoes.length>0" class="table table-bordered table-hover bg-white">
        <thead>
            <tr>
                <th>Data de fechamento</th>
                <th>Data de liquidação</th>
                <th>Cliente</th>
                <th>Natureza</th>
                <th>Moeda</th>
                <th>Tipo de transação</th>
                <th>Valor total</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cotacoes">
                <td>{{item.dataFechamento | date:'dd/MM/yyyy'}}</td>
                <td>{{item.dataLiquidacao | date:'dd/MM/yyyy'}}</td>
                <td>{{item.clienteNome }}</td>
                <td>{{item.naturezaNome }}</td>
                <td>{{item.moedaNome }}</td>
                <td>{{item.tipoTransacaoNome }}</td>
                <td>{{item.valorTotal | currency:'BRL':true:'1.2-4' }}</td>
                <td>
                    <a [routerLink]="[ item.id ]" class="btn btn-light btn-sm">
                        <i class="fa fa-calculator" aria-hidden="true"></i></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="row">
    <div class="col-lg-12">
        <pagination *ngIf="totalItems>itemsPerPage" [totalItems]="totalItems" [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)" [itemsPerPage]="itemsPerPage" [maxSize]="maxSize"></pagination>
    </div>
</div>