<div class="input-group mb-3">
  <span class="input-group-text" id="inputGroup-sizing-default">Pesquisar</span>
  <input
    type="text"
    placeholder="Nome, CPF ou CNPJ"
    [(ngModel)]="filtroLista"
    class="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
  />
</div>
<table class="table table-hover">
  <thead>
    <tr>
      <th>Tempo de Cadastro</th>
      <th>Nome</th>
      <th>CPF</th>
      <th>Empresa</th>
      <th>CNPJ</th>
      <th>Score</th>
      <th>Status</th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let matriz of matrizesFiltradas">
      <td>{{ matriz.tempoCadastro }}h.</td>
      <td>{{ matriz.nome }}</td>
      <td>{{ matriz.cpf }}</td>
      <td>{{ matriz.nomeEmpresa ? matriz.nomeEmpresa : "-" }}</td>
      <td>{{ matriz.cnpj ? matriz.cnpj : "-" }}</td>
      <td>{{ matriz.score }}</td>
      <td>
        {{ matriz.status + "  " }}
        <i
          matTooltip="{{ matriz.tooltipStatus }}"
          class="fa fa-info"
          aria-hidden="true"
        ></i>
      </td>
      <td>
        <button
          *ngIf="matriz.matrizAvaliada == true"
          class="btn btn-dark"
          (click)="
            openDialog(
              matriz.id,
              matriz.score,
              matriz.cpf,
              matriz.cnpj,
              matriz.nome,
              matriz.empresa,
              matriz.matrizAvaliada,
              false,
              matriz.limiteFinanceiro
            )
          "
        >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
        <button
          *ngIf="matriz.matrizAvaliada == false"
          class="btn btn-success"
          (click)="
            openDialog(
              matriz.id,
              matriz.score,
              matriz.cpf,
              matriz.cnpj,
              matriz.nome,
              matriz.empresa,
              matriz.matrizAvaliada,
              false,
              matriz.limiteFinanceiro
            )
          "
        >
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </button>
      </td>
      <td>
        <button
          class="btn btn-success"
          (click)="downloadDocument(matriz.cpf, matriz.cnpj, matriz.nome)"
        >
          <i class="fa fa-file-text-o" aria-hidden="true"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
