import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiquidacaoService {

  constructor(private http: HttpClient) { }

    obterLiquidacoes() {
        return this.http.get(environment.apiUrl + '/liquidacoes');
    }

    obterLiquidacao(id: any) {
        return this.http.get(environment.apiUrl + '/liquidacoes/' + id);
    }
}
