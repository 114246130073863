<div class="modal-header">
    <h4 class="modal-title">Upload {{titulo}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body">
    <div class="row mb-3">
        <div class="col-12">
            <div class="progress" *ngIf="progress > 0">
                <div class="progress-bar" role="progressbar" style="width: {{progress}}%" aria-valuenow="0"
                    aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>
    <div class="mb-3">
        <input class="form-control" type="file" #file placeholder="Seleciona o arquivo" accept="application/pdf" />
    </div>
    <button [disabled]="disabled" type="button" class="btn btn-primary" (click)="uploadFile(file.files)">Enviar</button>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="bsModalRef.hide()">
        <strong>Fechar</strong>
    </button>
</div>