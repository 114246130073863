import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OperacaoService {

    constructor(private http: HttpClient) { }

    map(operacao: any) {
        if (operacao.arquivoContrato)
            operacao.urlContrato = `${environment.apiUrl}/operacoes/download/contrato/${operacao.id}`;

        if (operacao.arquivoSwift)
            operacao.urlSwift = `${environment.apiUrl}/operacoes/download/swift/${operacao.id}`;

        return operacao;
    }


    obterOperacoesPorCliente(contrato: any, boletoChange: any, dataInicioOperacao: any, dataFimOperacao: any, pagadorRecebedor: any, tipoContratoId: any,
        clienteId: any, currentPage: any, pageSize: any) {

        const params = {
            clienteId,
            contrato,
            boletoChange,
            dataInicioOperacao,
            dataFimOperacao,
            pagadorRecebedor,
            tipoContratoId,
            currentPage,
            pageSize
        };

        return this.http.get(environment.apiUrl + '/operacoes/allByCliente', { params });
    }

    obterOperacao(id: any) {
        return this.http.get(environment.apiUrl + '/operacoes/' + id);
    }

    obterTiposDeContrato() {

        let result: any[] = [];
        result.push({ id: 1, nome: 'Exportação' });
        result.push({ id: 2, nome: 'Importação' });
        result.push({ id: 3, nome: 'Envio de Dinheiro' });
        result.push({ id: 4, nome: 'Recebimento de Dinheiro' });
        return result;

    }

    upload(data: any, uploadContrato: boolean) {
        if (uploadContrato) {
            return this.http.post(environment.apiUrl + '/operacoes/upload/contrato', data, { reportProgress: true, observe: 'events' });
        } else {
            return this.http.post(environment.apiUrl + '/operacoes/upload/swift', data, { reportProgress: true, observe: 'events' });
        }
    }

    
    obterDashboard() {
        return this.http.get(environment.apiUrl + '/operacoes/dashboard/');
    }

}