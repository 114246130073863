import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BancoService {

    constructor(private http: HttpClient) { }

    obterBancosOffline() {
        return this.http.get(environment.apiUrl + '/bancos/offline');
    }

    obterBancos() {
      return this.http.get(environment.apiUrl + '/bancos');
  }

  obterBancosAtivos() {
    return this.http.get(environment.apiUrl + '/bancos/ativos');
}

    obterBanco(id: any) {
        return this.http.get(environment.apiUrl + '/bancos/'+ id);
    }

    obterBancosComerciaisPorPhrase(phrase: any) {
        return this.http.get(environment.apiUrl + '/bancos/comerciais/phrase/' + phrase);
      }

}
