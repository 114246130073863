
<h1>Cotação #{{cotacao.idPublico}}</h1>


<form class="row" [formGroup]="form" (submit)="gravarAgencia()">
  <input type="hidden" readonly [value]="cotacao.id" formControlName="fechamentoId" id="fechamentoId">
<!--<validation-summary [form]="form" [fields]="fields" [showErrors]="showErrors"></validation-summary>-->
    <div class="col">

      <div class="row mt-2 mb-2">
        <label for="moeda" class="col-sm-6 col-form-label">Tipo de Transação</label>
        <div class="col-sm-6">
            <input type="text" readonly class="form-control-plaintext text-end" id="moeda" value="Enviar" *ngIf="cotacao.tipoTransacao == 0">
            <input type="text" readonly class="form-control-plaintext text-end" id="moeda" value="Receber" *ngIf="cotacao.tipoTransacao == 1">
        </div>
    </div>

        <div class="row mt-2 mb-2">
            <label for="cliente" class="col-sm-2 col-form-label">Cliente</label>
            <div class="col-sm-10">
                <input type="text" readonly class="form-control-plaintext text-end" id="cliente"
                    [value]="cotacao.cliente.nome">
            </div>
        </div>

        <div class="row mt-2 mb-2">
          <label for="natureza" class="col-sm-6 col-form-label">Natureza</label>
          <div class="col-sm-6">
              <input type="text" readonly class="form-control-plaintext text-end" id="natureza"
                  [value]="cotacao.natureza.numero + ' - ' + cotacao.natureza.nome">
          </div>
      </div>

      <div class="row mt-2 mb-2">
        <label for="liquidacao" class="col-sm-6 col-form-label">Liquidação</label>
        <div class="col-sm-6">
            <input type="text" readonly class="form-control-plaintext text-end" id="liquidacao"
                [value]="cotacao.liquidacao.nome">
        </div>
      </div>

      <div class="row mt-2 mb-2">
        <label for="liquidacao" class="col-sm-6 col-form-label">Data do Fechamento</label>
        <div class="col-sm-6">
            <input type="text" readonly class="form-control-plaintext text-end" id="liquidacao"
                [value]="cotacao.dataFechamento| date: 'dd/MM/yyyy HH:mm:ss'">
        </div>
      </div>

        <div class="row mb-2">
            <label for="banco" class="col-sm-6 col-form-label">Banco</label>
            <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.instituicao.nome + ' (' + cotacao.instituicao.numero + ')'">
            </div>
        </div>
        <!--
        <div class="row mt-2 mb-2">
            <label for="moeda" class="col-sm-6 col-form-label">Moeda</label>
            <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="moeda"
                    [value]="cotacao.moeda.nome">
            </div>
        </div>
        -->
        <div class="row mt-2 mb-2">
            <label for="valor" class="col-sm-6 col-form-label">Valor Cotado em <b>{{cotacao.moeda.nome}}</b></label>
            <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="valor"
                    [value]="cotacao.valorCotado | currency : cotacao.moeda.nome">
            </div>
        </div>

        <div class="row mb-2">
            <label for="cotacaoComercial" class="col-sm-6 col-form-label">Cotação comercial</label>
            <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="cotacaoComercial"
                    [value]="cotacao.valorCotacao | currency:'BRL':true:'1.4-4'">
            </div>
        </div>

        <div class="row mb-2">
            <label for="cotacaoComercial" class="col-sm-6 col-form-label">Spread</label>
            <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="cotacaoComercial"
                    [value]="cotacao.percentualSpread/100 | percent:'1.1'">
            </div>
        </div>

        <div class="row mb-2">
          <label for="cotacaoComercial" class="col-sm-6 col-form-label"><b>Cotação</b></label>
          <div class="col-sm-6">
              <input type="text" readonly class="form-control-plaintext text-end" id="cotacaoComercial"
                  [value]="cotacao.valorCotacaoSpread | currency:'BRL':true:'1.4-4'">
          </div>
        </div>

        <div class="row mb-2">
            <label for="cotacaoComercial" class="col-sm-6 col-form-label"><b>Valor da Operação</b></label>
            <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="cotacaoComercial"
                    [value]="cotacao.valorCotacaoSpread * cotacao.valorCotado | currency : 'BRL':true:'1.4-4'">
            </div>
        </div>

        <!--<div class="row mb-2">
            <label for="spread" class="col-sm-6 col-form-label">Spread ({{cotacao.percentualSpread}}%)</label>
            <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="spread"
                    [value]="cotacao.spread | currency : 'BRL'">
            </div>
        </div>-->

        <!--<div class="row mb-2">
          <label for="valorOperacao" class="col-sm-6 col-form-label">Valor da operação</label>
          <div class="col-sm-6">
            <input type="text" readonly class="form-control-plaintext text-end" id="valorOperacao">
          </div>
        </div>-->

        <div class="row mb-2">
            <label for="iof" class="col-sm-6 col-form-label">IOF ({{cotacao.percentualIof}}%)</label>
            <div class="col-sm-6">
               + <input type="text" readonly class="form-control-plaintext text-end" id="iof"
                    [value]="cotacao.iof | currency : 'BRL':true:'1.4-4'">
            </div>
        </div>

        <div class="row mb-2">
            <label for="iof" class="col-sm-6 col-form-label">IR ({{cotacao.percentualIr}}%)</label>
            <div class="col-sm-6">
                + <input type="text" readonly class="form-control-plaintext text-end" id="ir"
                    [value]="cotacao.ir | currency : 'BRL':true:'1.4-4'">
            </div>
        </div>

        <div class="row mb-2">
            <label for="taxasExternas" class="col-sm-6 col-form-label">Taxa ADM</label>
            <div class="col-sm-6">
                +  <input type="text" readonly class="form-control-plaintext text-end" id="taxasExternas"
                    [value]="cotacao.taxasExternas | currency : 'BRL'">
            </div>
        </div>

        <div class="row mb-2">
            <label for="valorEfetivo" class="col-sm-6 col-form-label">Valor Efetivo Total (VET)</label>
            <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="valorEfetivo"
                    [value]="cotacao.valorEfetivo | currency : 'BRL':true:'1.12-12'">
            </div>
        </div>

        <div class="row mb-2">
            <label for="valorTotal" class="col-sm-6 col-form-label"><b>Valor total</b></label>
            <div class="col-sm-6">
              =  <input type="text" readonly class="form-control-plaintext text-end" id="valorTotal"
                    [value]="cotacao.valorTotal | currency : 'BRL'">
            </div>
        </div>



        <!-- Se for envio, mostramos as instruções para depósito -->
        <div *ngIf="cotacao.tipoTransacao == 0">
          <h1>Instruções para Depósito</h1>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-6 col-form-label">Banco</label>
              <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.instituicao.nome + ' (' + cotacao.instituicao.numero + ')'">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-6 col-form-label">Agência</label>
              <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.instituicao.agencia">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-6 col-form-label">Conta Corrente</label>
              <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.instituicao.contaCorrente">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-3 col-form-label">Favorecido</label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.instituicao.favorecido">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-3 col-form-label">CNPJ</label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.instituicao.cnpj">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-3 col-form-label">PIX</label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.instituicao.pix">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-3 col-form-label"><b>VALOR PARA DEPÓSITO</b></label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext text-end" id="valorTotal"
                    [value]="cotacao.valorTotal | currency : 'BRL'">
              </div>
          </div>


          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-3 col-form-label"><b>DATA PARA DEPÓSITO</b></label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext text-end" id="valorTotal"
                    [value]="cotacao.dataLiquidacao | date: 'dd/MM/yyyy'">
              </div>
          </div>

        </div>

        <!-- Se for recebimento, o operador deve informar os dados bancários do cliente -->
        <!-- ÁREA PARA COLOCAR OS DADOS BANCÁRIOS DO CLIENTE -->
        <div *ngIf="cotacao.tipoTransacao == 1 &&  form.value.fechamentoCompleto == false">
          <h1>Dados Bancários do Cliente</h1>

          <div class="row mb-2">
            <!--<label for="bancoCliente" class="col-sm-6 col-form-label">Banco</label>-->
            <div class="col-sm-12">
              <input [ngModelOptions]="{standalone: true}" [(ngModel)]="bancoSearch" [typeahead]="bancoSuggestions$"
              (typeaheadLoading)="bancoChangeTypeaheadLoading($event)"
              (typeaheadOnSelect)="bancoTypeaheadOnSelect($event)" (typeaheadOnBlur)="bancoTypeaheadOnBlur($event)"
              [typeaheadWaitMs]="300" [typeaheadOptionsLimit]="7" [typeaheadOptionField]="'nome'" placeholder="Banco: Pesquise por número ou nome"
              class="form-control" >
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-4">
                <input type="text" class="form-control" formControlName="clienteAgencia" id="clienteAgencia" placeholder="Agência">
            </div>
            <div class="col-sm-8">
              <input type="text" class="form-control" aria-label="Text input with dropdown button" formControlName="clienteContaCorrente" id="clienteContaCorrente" placeholder="Conta Corrente" >
            </div>
          </div>

        </div>
        <!-- FIM DA ÁREA PARA COLOCAR OS DADOS BANCÁRIOS DO CLIENTE -->

        <div class="row mb-2" *ngIf="form.value.fechamentoCompleto == false && cotacao.tipoTransacao == 1">
          <div class="col">
              <div class="d-grid gap-2">
                  <button type="submit" class="btn btn-success">Gravar Dados Bancários</button>
              </div>
          </div>
        </div>

        <!-- Se for recebimento, mostramos as instruções para o crédito -->
        <div *ngIf="cotacao.tipoTransacao == 1 &&  form.value.fechamentoCompleto == true">
          <h1>Instruções para Crédito</h1>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-6 col-form-label">Banco</label>
              <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.clienteDadoBancario.bancoComercial.nome">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-6 col-form-label">Agência</label>
              <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="form.value.clienteAgencia">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-6 col-form-label">Conta Corrente</label>
              <div class="col-sm-6">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="form.value.clienteContaCorrente">
              </div>
          </div>

          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-3 col-form-label">Favorecido</label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext text-end" id="banco"
                    [value]="cotacao.cliente.nome">
              </div>
          </div>


          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-3 col-form-label"><b>VALOR PARA CRÉDITO</b></label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext text-end" id="valorTotal"
                    [value]="cotacao.valorTotal | currency : 'BRL'">
              </div>
          </div>


          <div class="row mb-2">
            <label for="valorTotal" class="col-sm-3 col-form-label"><b>DATA PARA CRÉDITO</b></label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext text-end" id="valorTotal"
                    [value]="cotacao.dataLiquidacao | date: 'dd/MM/yyyy'">
              </div>
          </div>
        </div>

        <div class="row mb-2"  *ngIf="form.value.fechamentoCompleto == true">
          <div class="col">
              <div class="d-grid gap-2"><BR><BR><BR>
                  <button (click)="enviarEmail()" type="button" class="btn btn-success ms-1" id="btnEnviarEmail">Enviar E-mail</button>
              </div>
          </div>
      </div>

        <div class="row mb-2"  *ngIf="form.value.fechamentoCompleto == true">
            <div class="col">
                <div class="d-grid gap-2"><BR>
                    <button (click)="back()" type="button" class="btn btn-light ms-1">Voltar</button>
                </div>
            </div>
        </div>

    </div>
</form>
<br><br><br><br><br><br>