import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) { }

  obterClientes() {
    return this.http.get(environment.apiUrl + '/clientes');
  }

  obterCliente(id: any) {
    return this.http.get(environment.apiUrl + '/clientes/' + id);
  }

  obterClientesPorPhrase(phrase: any) {
    return this.http.get(environment.apiUrl + '/clientes/phrase/' + phrase);
  }
}
