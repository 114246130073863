<div class="input-group mb-3">
  <span class="input-group-text" id="inputGroup-sizing-default">Pesquisar</span>
  <input
    type="text"
    placeholder="Tipo ou Nome"
    [(ngModel)]="filtroLista"
    class="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
  />
</div>
<div class="d-flex bd-highlight">
  <div class="p-2 flex-grow-1 bd-highlight"></div>
  <div class="p-2 bd-highlight">
    <button
      type="button"
      class="btn btn-success"
      style="text-transform: none"
      (click)="openDialog()"
    >
      Novo <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </button>
  </div>
</div>

<table class="table table-hover">
  <thead>
    <tr>
      <th>Tipo</th>
      <th>Nome</th>
      <th>Tipo de Dado</th>
      <th>Operador Lógico</th>
      <th>Valor1</th>
      <th>Valor2</th>
      <th>Score</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let parametro of parametrosFiltrados">
      <td>{{ parametro.tipoParametro }}</td>
      <td>{{ parametro.jsonPropertyDescricao }}</td>
      <td>{{ parametro.tipoDado }}</td>
      <td>{{ parametro.operadorLogicoDescricao }}</td>
      <td>{{ parametro.valor1 }}</td>
      <td>{{ parametro.valor2 }}</td>
      <td>{{ parametro.score }}</td>
      <td>
        <button class="btn btn-danger">
          <i
            class="fa fa-trash"
            aria-hidden="true"
            (click)="putInativaParametro(parametro.id)"
          ></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
