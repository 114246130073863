import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import jwt_decode from "jwt-decode";
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})

export class AuthTokenService implements CanActivate{
  public onsignOutEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public onsignInEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  private perfilPermissions: Map<string, string[]> = new Map<string, string[]>();

  constructor(private router: Router) {
    this.perfilPermissions.set('Dashboard', ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE']);
    this.perfilPermissions.set('Cotacao', ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE']);
    this.perfilPermissions.set('Operacao', ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE']);
    this.perfilPermissions.set('TempoReal', ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE']);
    this.perfilPermissions.set('Compliance', ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE']);
    this.perfilPermissions.set('Financeiro', ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE']);
    this.perfilPermissions.set('Parametros', ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE']);
  }

  navBarItemPermission(navItem: string): boolean {
    var retorno = false;
    const userPefil = this.getPerfil();

    const allowedRoutes: string[] = [];
    this.perfilPermissions.forEach((roles, area) => {
      if (area == navItem && roles.includes(userPefil)) {
        retorno = true;
      }
    });

    return retorno;
  }

  authenticated() {
    var token = this.getToken();
    if (token)
      return true;

    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkUserRole(route);
  }

  checkUserRole(route: ActivatedRouteSnapshot): boolean {
    const requiredRoles: string[] = route.data['roles'];

    if (!this.isRoleAuthorized(requiredRoles) || !this.isLoggedIn()){
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }

  isLoggedIn() {
    var token = this.getToken();
    if (token)
      return true;

    return false;
  }

  public signIn(token: string) {
    this.setToken(token);
    this.onsignInEmitter.emit(true);
  }

  public signOut() {
    localStorage.removeItem('zebra.token')
    localStorage.removeItem('zebra.perfil')
    this.onsignOutEmitter.emit(true);
    this.router.navigate(['/']);
  }

  public getUser(): Usuario {
    var decodeToken = this.decodeJwt();
    return new Usuario(decodeToken);
  }

  setToken(token: string) {
    localStorage.setItem('zebra.token', token);
  }

  setPerfil(perfil: string) {
    localStorage.setItem('zebra.perfil', perfil);
  }

  getToken(): string {
    return localStorage.getItem('zebra.token') || "";
  }

  getPerfil(): string {
    return localStorage.getItem('zebra.perfil') || "";
  }

  decodeJwt(): any {
    try {
      return jwt_decode(this.getToken());
    } catch (Error) {
      return null;
    }
  }

  private isRoleAuthorized(requiredRole: string[]): boolean {
    const userRole = this.getPerfil();
    var retorno: boolean = false;

    //quer dizer que a rota não pussui a regra de bloqueio por role
    if(!requiredRole || requiredRole.length === 0){
      retorno = true;
      return retorno;
    }

    requiredRole.forEach(x => {
      if(x === userRole){
        retorno = true;
      }

      if(retorno == true){
        return;
      }
    });

    return retorno;
  }

}
