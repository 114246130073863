import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MessageService } from '../message.service';
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private messageService: MessageService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    
                    if (error.status == 401) {
                        setTimeout(() => {
                            this.router.navigate(['/']);
                            return of();
                        }, 500);
                    }

                    if (error.status === 403) {
                        setTimeout(() => {
                            this.messageService.warning('Acesso negado');
                            return of();
                        }, 500);
                    }

                    if (error.status == 400) {
                        this.messageService.warning(error.error);
                        return of();
                    }

                    if (error.status == 500) {
                        this.messageService.danger(error.error);
                        return of();
                    }

                    if (error.status == 0) {
                        this.messageService.danger('Sem conexão com a API');
                        return of();
                    }

                    return throwError(error);

                }) // catchError
            ) // pipe
    }
}