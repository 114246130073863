import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { CotacaoService } from 'src/app/services/cotacao.service';
import { LoginService } from 'src/app/services/login.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-notificar-queda-alta-page',
  templateUrl: './notificar-queda-alta-page.component.html',
  styleUrls: ['./notificar-queda-alta-page.component.scss']
})
export class NotificarQuedaAltaPageComponent implements OnInit {

  private modalRef?: BsModalRef;
  public form: FormGroup;
  public disabled: boolean = false;

  public fields: { [index: string]: string } = {};
  public showErrors: boolean = false;

  constructor(
    private fb: FormBuilder,
    private cotacaoService: CotacaoService,
    private messageService: MessageService,
    private modalService: BsModalService,
  ) {

    this.form = this.fb.group({
      valorCotacao: [0, [Validators.required, Validators.min(0), Validators.max(999)]],
    });

  }

  ngOnInit(): void {
    this.fields["valorCotacao"] = "Valor cotação";
  }

  notificar() {

    if (this.form.invalid) {
      this.showErrors = true;
      return;
    };

    this.modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Notificar cotação',
        text: 'Confirma a notificação dos clientes?',
      }
    });

    this.modalRef.content.onClose.subscribe((res: any) => {
      if (res) {
        const data = {
          valorCotacao: this.form.value.valorCotacao
        };

        this.cotacaoService.notificarQuedaAlta(data).subscribe(
          (data: any) => {
            this.messageService.success(`${data} clientes notificados com sucesso.`);
            this.form.reset();
          });
      }
    });

  }

}
