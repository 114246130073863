import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthTokenService } from 'src/app/services/auth-token.service';
import { LocalStorageUtils } from 'src/app/utils/localstorage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  static autenticado: boolean = false;
  //sub: Subscription[] = [];
  public navbarOpen = false;
  public ambientehomologacao = environment.ambientehomologacao;

  constructor(public authTokenService: AuthTokenService) { }

  get isAuthenticated() {
    return NavbarComponent.autenticado;
  }

  ngOnInit(): void {

    NavbarComponent.autenticado = this.authTokenService.authenticated();

    /*this.sub.push(
      this.authTokenService.onsignInEmitter.subscribe((e) => {
        NavbarComponent.autenticado = true;
      })
    );

    this.sub.push(
      this.authTokenService.onsignOutEmitter.subscribe((e) => {
        NavbarComponent.autenticado = false;
      })
    );*/
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnDestroy(): void {
    /*this.sub.forEach(s => {
      s.unsubscribe();
    });*/
  }

  sair() {
    this.authTokenService.signOut();
  }

validaRole(navItem: string): boolean{
  var retorno = this.authTokenService.navBarItemPermission(navItem);
  return retorno;
}

}
