<div class="row justify-content-center">
    <div class="col col-sm-6 col-md-6 col-lg-5 col-xl-4">
        <h1>Notificar cotação</h1>
        <p>
            Notificar clientes que operaram nos últimos 30 dias informando a cotação atual do dolar.
        </p>
        <form [formGroup]="form" (submit)="notificar()">
            <div class="mb-3">
                <label for="valorCotacao" class="form-label">Valor cotacao</label>
                <input currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision : 4 }" autofocus type="text" class="form-control" id="valorCotacao"
                    placeholder="" formControlName="valorCotacao">
            </div>
            <button class="w-100 btn btn-lg btn-primary" type="submit">Notificar clintes</button>
        </form>

    </div>
</div>