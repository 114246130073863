<div class="card mt-3 mb-3 shadow-sm">
    <div class="card-body">
        <h3>Cotação Manual</h3>
    </div>
</div>
<div class="table-responsive">
    <table class="table table-bordered table-hover bg-white">
        <thead>
            <tr>
                <th>Instituição</th>
                <th>Moeda</th>
                <th>Liquidação</th>
                <th>Transação</th>
                <th>Última Cotação</th>
                <th>Valido Até</th>
                <th>Valor da Cotação</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cotacoesVigente">
                <td>{{item.instituicaoNome }}</td>
                <td>{{item.moedaNome }}</td>
                <td>{{item.liquidacaoNome}}</td>
                <td>{{item.tipoTransacaoNome}}</td>
                <td>{{item.dataCotacao | date:'dd/MM/yyyy HH:mm'}}</td>
                <td>{{item.validade | date:'dd/MM/yyyy HH:mm'}}</td>
                <td>
                    <input type="text" class="form-control" aria-label="Text input with dropdown button" maxlength="12"
                        (blur)="saveValorCotacao(item)" [(ngModel)]="item.valorCotacao" currencyMask
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision : 4 }">
                </td>
            </tr>
        </tbody>
    </table>
</div>