<div class="row justify-content-center">
    <div class="col col-sm-10 col-md-10">
        <h1>Insira a quantidade e selecione moeda</h1>
        <p>Você quer:</p>

        <tabset #staticTabs>
            <tab heading="Enviar">
                <app-cotacao [tipoTransacao]="0"> </app-cotacao>
            </tab>
            <tab heading="Receber">
                <app-cotacao [tipoTransacao]="1"> </app-cotacao>
            </tab>
        </tabset>

    </div>
</div>