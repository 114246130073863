import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthJWTService } from 'src/app/services/auth-jwt.service';
import { MessageService } from 'src/app/services/message.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-recuperar-senha-page',
  templateUrl: './recuperar-senha-page.component.html',
  styleUrls: ['./recuperar-senha-page.component.scss']
})
export class RecuperarSenhaPageComponent implements OnInit {

  public disabled: boolean = false;
  public form: FormGroup;
  public codeEncrypt: string = '';

  public fields: { [index: string]: string } = {};
  public showErrors: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private messageService: MessageService) {

    this.form = this.fb.group({
      codigo: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.fields["codigo"] = "Código";
    this.codeEncrypt = this.route.snapshot.params['codeEncrypt'];
  }

  validade() {
    let code = this.form.value.codigo;

    if (!code) {
      this.messageService.warning('Código inválido.');
      return false;
    }

    if (isNaN(code)) {
      this.messageService.warning('Código inválido.');
      return false;
    }

    if (code.length != 4) {
      this.messageService.warning('Código inválido.');
      return false;
    }

    return true;
  }

  send() {
    if (this.form.invalid) {
      this.showErrors = true;
      return;
    };

    const data = {
      code: this.form.value.codigo,
      codeEncrypt: this.codeEncrypt
    };

    this.loginService.recuperarSenha(data).subscribe((data: any) => {
      this.messageService.success('Uma nova senha foi enviada para o seu e-mail.')
      this.router.navigate(['']);
    });

  }
}