import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { OperacaoService } from 'src/app/services/operacao.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { AuthTokenService } from 'src/app/services/auth-token.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { Observable, Subject, timer } from 'rxjs';
import { UploadArquivosComponent } from 'src/app/components/upload-arquivos/upload-arquivos.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-operacoes-page',
  templateUrl: './operacoes-page.component.html',
  styleUrls: ['./operacoes-page.component.scss']
})

export class OperacoesPageComponent implements OnInit {

  bsModalRef: BsModalRef | undefined;
  public operacoes: any[] = [];
  public clientes: any[] = [];
  public tiposDeContrato: any[] = this.operacaoService.obterTiposDeContrato();

  public form: FormGroup;

  //pagination
  public currentPage: number = 1;
  public totalItems: number = 0;
  public itemsPerPage: number = 25;
  public maxSize: number = 25;
  //end pagination

  //typeahead
  public clienteSearch?: string;
  public clienteSuggestions$?: Observable<any[]>;
  public clienteTypeaheadLoading: boolean = false;
  public clienteTypeaheadNoResults: boolean = false;
  //end typeahead

  constructor(private fb: FormBuilder,
    private operacaoService: OperacaoService,
    private authTokenService: AuthTokenService,
    private modalService: BsModalService,
    private clienteService: ClienteService) {

    this.form = this.fb.group({
      contrato: [''],
      boletoChange: [''],
      dataInicioOperacao: [''],
      dataFimOperacao: [''],
      pagadorRecebedor: [''],
      tipoContratoId: [''],
      clienteId: ['']
    });
  }


  ngOnInit(): void {
    this.carregarClientes();
    this.list();
  }

  search() {
    this.currentPage = 1;
    this.list();
  }

  list() {

    let contrato: any = this.form.controls["contrato"].value;
    let boletoChange: any = this.form.controls["boletoChange"].value;
    let dataInicioOperacao: any = this.form.controls["dataInicioOperacao"].value;
    let dataFimOperacao = this.form.controls["dataFimOperacao"].value;
    let pagadorRecebedor: any = this.form.controls["pagadorRecebedor"].value;
    let tipoContratoId: any = this.form.controls["tipoContratoId"].value;
    let clienteId: any = this.form.controls["clienteId"].value;

    this.operacaoService.obterOperacoesPorCliente(contrato, boletoChange, dataInicioOperacao, dataFimOperacao, pagadorRecebedor, tipoContratoId,
      clienteId, this.currentPage, this.itemsPerPage)
      .subscribe((result: any) => {
        this.operacoes = result.itens.map((o: any) => {
          return this.operacaoService.map(o);
        });
        this.totalItems = result.total;
      });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.list();
  }

  carregarClientes() {
    this.clienteSuggestions$ = Observable.create((observer: any) => {
      this.clienteService.obterClientesPorPhrase(this.clienteSearch).subscribe((result: any) => {
        observer.next(result);
      })
    });
  }

  clienteChangeTypeaheadLoading(e: boolean): void {
    this.clienteTypeaheadLoading = e;
  }

  clienteTypeaheadOnSelect(e: any): void {
    this.form.controls["clienteId"].setValue(e.item.id);
  }

  clienteTypeaheadOnBlur(e: any): void {
    if (!e || !e.item) this.form.controls["clienteId"].setValue('');
  }

  upload(operacao: any, uploadContrato: boolean) {

    const config: ModalOptions = {
      initialState: {
        operacao: operacao,
        uploadContrato: uploadContrato,
        onClose: new Subject()
      },
      class: 'modal-md',
      backdrop: true,
      ignoreBackdropClick: false,
    };

    this.bsModalRef = this.modalService.show(
      UploadArquivosComponent,
      config
    );

    this.bsModalRef.content.onClose.subscribe((operacao: any) => {
      this.list();
    });



  }

}
