import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { MatrizRiscoService } from 'src/app/services/matriz-risco.service';
import { DialogContentComponent } from '../dialog-content/dialog-content.component';
import { MatrizRiscoParametro } from 'src/app/models/matriz-risco-parametro';
import { DialogParametrosComponent } from '../dialog-parametros/dialog-parametros.component';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.scss'],
})
export class ParametrosComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private matrizRiscoService: MatrizRiscoService,
    private router: Router
  ) {}

  parametros: MatrizRiscoParametro[] = [];

  private _filtroLista: string = '';
  parametrosFiltrados: any = this.parametros;
  retornoString: string = "";

  public get filtroLista(): string {
    return this._filtroLista;
  }

  public set filtroLista(value: string) {
    this._filtroLista = value;
    this.parametrosFiltrados = this.filtroLista
      ? this.filtrarLista(this.filtroLista)
      : this.parametros;
  }

  filtrarLista(filtrarPor: string): any {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.parametros.filter(
      (parametros) =>
        parametros.tipoParametro.toLocaleLowerCase().indexOf(filtrarPor) !==
          -1 ||
        parametros.jsonPropertyDescricao
          .toLocaleLowerCase()
          .indexOf(filtrarPor) !== -1
    );
  }

  ngOnInit(): void {
    this.getAll();
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogParametrosComponent, {
      data: { id: 0 }});

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);

      const navigationExtras: NavigationExtras = {
        skipLocationChange: true,
      };

      if (result == true) {
        this.router.navigate(['/restrito/parametros']).then(() => {
          window.location.reload();
        });
      }
    });
  }

  getAll() {
    this.matrizRiscoService
      .getallParametros()
      .subscribe(
        (retorno: MatrizRiscoParametro[]) => {
          this.parametros = retorno;
          this.parametrosFiltrados = retorno;
        },
        () => {},
        () => {}
      )
      .add();
  }

  putInativaParametro(id: number) {
    this.matrizRiscoService
      .putInativaParametro(id)
      .subscribe(
        (retorno: string) => {
          this.retornoString = retorno;
          alert(this.retornoString);

          const navigationExtras: NavigationExtras = {
            skipLocationChange: true
          };
          this.router.navigate(['/restrito/parametros']).then(() => {
            window.location.reload();
          });

        },
        () => {},
        () => {}
      )
      .add();
  }

}
