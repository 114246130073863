<div class="row justify-content-center">
    <div class="col col-sm-6 col-md-6 col-lg-5 col-xl-4">
        <h1>Alterar senha</h1>

        <form [formGroup]="form" (submit)="alterarSenha()">
            <!--<validation-summary [form]="form" [fields]="fields" [showErrors]="showErrors"></validation-summary>-->
            <div class="mb-3">
                <label for="email" class="form-label">Senha atual</label>
                <input maxlength="100" autofocus type="password" class="form-control" id="senhaAtual"
                    placeholder="Senha atual" formControlName="senhaAtual">
            </div>
            <div class="mb-3">
                <label for="senha" class="form-label">Nova senha</label>
                <input maxlength="100" type="password" class="form-control" id="novaSenha" placeholder="Nova senha"
                    formControlName="novaSenha">
            </div>
            <div class="mb-3">
                <label for="senha" class="form-label">Confirme nova senha</label>
                <input maxlength="100" type="password" class="form-control" id="confirmeNovaSenha"
                    placeholder="Confirme nova senha" formControlName="confirmeNovaSenha">
            </div>
            <button class="w-100 btn btn-lg btn-primary" type="submit">Alterar</button>
        </form>

    </div>
</div>