import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RecuperarSenhaPageComponent } from './pages/recuperar-senha-page/recuperar-senha-page.component';
import { AuthTokenService } from './services/auth-token.service';
import { EsqueciMinhaSenhaPageComponent } from './pages/esqueci-minha-senha-page/esqueci-minha-senha-page.component';
import { CotacaoPageComponent } from './pages/cotacao-page/cotacao-page.component';
import { CotacaoVisualizarPageComponent } from './pages/cotacao-visualizar-page/cotacao-visualizar-page.component';
import { AlterarSenhaComponent } from './pages/alterar-senha/alterar-senha.component';
import { OperacoesPageComponent } from './pages/operacoes-page/operacoes-page.component';
import { CotacoesManualPageComponent } from './pages/cotacao-manual-page/cotacao-manual-page.component';
import { CotacoesTempoRealPageComponent } from './pages/cotacao-tempo-real-page/cotacao-tempo-real-page.component';
import { NotificarQuedaAltaPageComponent } from './pages/notificar-queda-alta-page/notificar-queda-alta-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { CotacoesPageComponent } from './pages/cotacoes-page/cotacoes-page.component';
import { ComplianceComponent } from './components/compliance/compliance.component';
import { FinanceiroComponent } from './components/financeiro/financeiro.component';
import { ParametrosComponent } from './components/parametros/parametros.component';

const routes: Routes = [
  { path: '', component: LoginPageComponent, pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
  { path: 'esqueci-minha-senha', component: EsqueciMinhaSenhaPageComponent },
  { path: 'recuperar-senha/:codeEncrypt', component: RecuperarSenhaPageComponent },
  {
    path: 'restrito', component: HomePageComponent, children: [
      { path: '', component: DashboardPageComponent, canActivate: [AuthTokenService] },
      { path: 'cotacao', component: CotacaoPageComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'cotacao/:id', component: CotacaoVisualizarPageComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'cotacoes', component: CotacoesPageComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'cotacoes/:id', component: CotacaoVisualizarPageComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'alterar-senha', component: AlterarSenhaComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'operacoes', component: OperacoesPageComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'cotacao-manual', component: CotacoesManualPageComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'tempo-real', component: CotacoesTempoRealPageComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'notificar-queda-alta', component: NotificarQuedaAltaPageComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'compliance', component: ComplianceComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'financeiro', component: FinanceiroComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
      { path: 'parametros', component: ParametrosComponent, canActivate: [AuthTokenService], data:{ roles: ['ADMINISTRADOR', 'MESA', 'FINANCEIRO', 'COMPLIANCE'] } },
    ]
  },
  { path: '**', redirectTo: 'restrito', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
