<!-- <div class="card mt-3 mb-3 shadow-sm" *ngIf="sumario">
    <div class="card-body">
        <h3>Dashboard</h3>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <strong>{{sumario.clientesNaoOperamMaisXDias}}</strong> clientes não operam a mais de <strong>{{sumario.clientesNaoOperamMaisXDiasValor}}</strong> dias.
           </li>
            <li class="list-group-item">
                <strong>{{sumario.clientesOperamUltimosXDias}}</strong> clientes que operaram nos ultimos <strong>{{sumario.clientesOperamUltimosXDiasValor}}</strong> dias.
           </li>
            <li class="list-group-item">
                <strong>{{sumario.totalClientes}}</strong> de clientes na base
            </li>
            <li class="list-group-item">
                <strong>{{sumario.totalOperacoesUltimosXDias}}</strong> operações nos últimos <strong>{{sumario.totalOperacoesUltimosXDiasValor}}</strong> dias.
            </li>
            <li class="list-group-item">
                <strong>{{sumario.operacoesSemSwiftMaisXDias}}</strong> operações sem swift a mais de <strong>{{sumario.operacoesSemSwiftMaisXDiasValor}}</strong> dias.
            </li>
        </ul>
    </div>
</div> -->

<div class="row">
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Compliance</h3>
        <p class="card-text">Avaliação da matriz de risco.</p>
        <a href="restrito/compliance" class="btn btn-dark">Acessar</a>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Financeiro</h3>
        <p class="card-text">Conferência do limite financeiro.</p>
        <a href="restrito/financeiro" class="btn btn-dark">Acessar</a>
      </div>
    </div>
  </div>
</div>
