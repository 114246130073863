import { HttpEventType } from '@angular/common/http';
import { COMPILER_OPTIONS, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';
import { OperacaoService } from 'src/app/services/operacao.service';

@Component({
  selector: 'app-upload-arquivos',
  templateUrl: './upload-arquivos.component.html',
  styleUrls: ['./upload-arquivos.component.scss']
})
export class UploadArquivosComponent implements OnInit {

  public operacao: any;
  public uploadContrato: boolean = false;
  public onClose: Subject<any> = new Subject();
  public progress: number = 0;
  public disabled: boolean = false;
  titulo: string = "Contrato";

  constructor(
    public bsModalRef: BsModalRef,
    private operacaoService: OperacaoService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.titulo = this.uploadContrato ? "Contrato" : "Swift";
  }


  public uploadFile = (files: any) => {

    if (files.length === 0) {
      this.messageService.warning('Arquivo é requerido.');
      return;
    }

    this.disabled = true;

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('operacaoId', this.operacao.id.toString());
    formData.append('file', fileToUpload, fileToUpload.name);

    this.operacaoService.upload(formData, this.uploadContrato).subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);
      }
      else if (event.type === HttpEventType.Response) {
        this.disabled = false;
        this.progress = 0;
        this.onClose.next(event.body);
        this.messageService.success('Upload realizado com sucesso.');
        this.bsModalRef.hide();

      }
    },
      (error: any) => {
        this.disabled = false;
        if (error && error.error) this.messageService.danger(error.error)
      });


  }

}