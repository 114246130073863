import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/internal/operators/take';
import { OperacaoService } from 'src/app/services/operacao.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

  sumario?: any;

  constructor(private operacaoService: OperacaoService) { }

  ngOnInit(): void {
    this.operacaoService.obterDashboard().pipe(take(1)).subscribe((res: any) => {
      this.sumario = res;
    });
  }

}