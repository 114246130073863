<div class="alert-box">
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </div>
    <div class="modal-body">
        {{text}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="confirm()">Sim</button>
        <button type="button" class="btn btn-light" (click)="cancel()">Não</button>        
    </div>
</div>