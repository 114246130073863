import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { AlertModelComponent } from '../components/alert-model/alert-model.component';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(private modalService: BsModalService) { }
    bsModalRef: BsModalRef | undefined;

    private showAlert(
        title: string,
        message: string,
        type: any,
        toaster: boolean,
        buttonColor: string
    ) {

        const config: ModalOptions = {
            initialState: {
                type: type,
                message: message,
                title: title
            },
            class: 'modal-md',
            backdrop: true,
            ignoreBackdropClick: false,
        };

        this.bsModalRef = this.modalService.show(
            AlertModelComponent,
            config
        );

    }

    private alert(
        titulo: string,
        mensagem: string[] | string | any[],
        tipo: string,
        toaster: boolean,
        buttonColor: string
    ) {
        if (typeof mensagem === 'string') {
            this.showAlert(titulo, mensagem, tipo, toaster, buttonColor);
        } else {
            if (Array.isArray(mensagem)) {
                if (typeof mensagem[0] === 'string') {
                    this.showAlert(
                        titulo,
                        mensagem.join('<br/>'),
                        tipo,
                        toaster,
                        buttonColor
                    );
                } else {
                    const arr: string[] = [];
                    mensagem.forEach((item) => {
                        arr.push(item.message);
                    });
                    this.showAlert(
                        titulo,
                        arr.join('<br/>'),
                        tipo,
                        toaster,
                        buttonColor
                    );
                }
            }
        }
    }

    success(mensagem: string[] | string | any[], toaster: boolean = false) {
        this.alert('SUCESSO', mensagem, 'success', toaster, '#479f76');
    }

    danger(mensagem: string[] | string, toaster: boolean = false) {
        this.alert('ERRO', mensagem, 'danger', toaster, '#e35d6a');
    }

    warning(mensagem: string[] | string, toaster: boolean = false) {
        this.alert('ATENÇÃO', mensagem, 'warning', toaster, '#ffcd39');
    }

    info(mensagem: string[] | string, toaster: boolean = false) {
        this.alert('INFORMAÇÃO', mensagem, 'info', toaster, '#3d8bfd');
    }
}
