<div class="navbar navbar-expand-lg fixed-top navbar-light bg-dark px-5">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img
        *ngIf="!ambientehomologacao"
        src="../../../assets/logo.svg"
        alt="ZEBRA"
        style="height: 28px; width: 127px"
      />
      <div *ngIf="ambientehomologacao" class="btn btn-danger">
        AMBIENTE HOMOLOGAÇÃO
      </div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      *ngIf="isAuthenticated"
      (click)="toggleNavbar()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      [ngClass]="{ show: navbarOpen }"
    >
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="isAuthenticated">
        <li class="nav-item" *ngIf="this.validaRole('Dashboard')">
          <a
            class="nav-link text-light"
            aria-current="page"
            [routerLink]="['/restrito']"
            title="Resumo da operação"
            >Home</a
          >
        </li>
        <!-- <li class="nav-item dropdown" *ngIf="this.validaRole('Cotacao')">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarScrollingDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Cotação
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
              <li>
                <a
                  class="dropdown-item"
                  href="#"
                  [routerLink]="['/restrito/cotacao']"
                  >Nova Cotação</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="#"
                  [routerLink]="['/restrito/cotacoes']"
                  >Cotações</a
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  class="dropdown-item"
                  href="#"
                  [routerLink]="['/restrito/cotacao-manual']"
                  >Cotação manual</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item" *ngIf="this.validaRole('Operacao')">
            <a
              class="nav-link"
              aria-current="page"
              [routerLink]="['/restrito/operacoes']"
              title="Visualizar as operações efetivadas no backoffice"
              >Operações</a
            >
          </li>
          <li class="nav-item" *ngIf="this.validaRole('Operacao')">
            <a
              class="nav-link"
              aria-current="page"
              [routerLink]="['/restrito/tempo-real']"
              title="Cotação em tempo real dos nossos bancos"
              >Tempo Real</a
            >
          </li> -->
        <li class="nav-item" *ngIf="this.validaRole('Compliance')">
          <a
            class="nav-link text-light"
            aria-current="page"
            [routerLink]="['/restrito/compliance']"
            title="Cotação em tempo real dos nossos bancos"
            >Compliance</a
          >
        </li>
        <li class="nav-item" *ngIf="this.validaRole('Financeiro')">
          <a
            class="nav-link text-light"
            aria-current="page"
            [routerLink]="['/restrito/financeiro']"
            title="Cotação em tempo real dos nossos bancos"
            >Financeiro</a
          >
        </li>
        <li class="nav-item" *ngIf="this.validaRole('Parametros')">
          <a
            class="nav-link text-light"
            aria-current="page"
            [routerLink]="['/restrito/parametros']"
            title="Cotação em tempo real dos nossos bancos"
            >Parâmetros</a
          >
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item dropdown me-3" *ngIf="this.validaRole('Cotacao')">
          <a
            class="nav-link dropdown-toggle text-light"
            href="#"
            id="navbarScrollingDropdownLeave"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Sair
          </a>
          <ul
            class="dropdown-menu"
            aria-labelledby="navbarScrollingDropdownLeave"
          >
            <li>
              <a
                class="dropdown-item"
                href="#"
                [routerLink]="['/restrito/alterar-senha']"
                >Alterar Senha</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a class="dropdown-item" href="#" (click)="sair()">Sair</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
