import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MoedaService {

    constructor(private http: HttpClient) { }

    obterMoedas() {
        return this.http.get(environment.apiUrl + '/moedas');
    }

    obterMoeda(id: any) {
        return this.http.get(environment.apiUrl + '/moedas/' + id);
    }
}