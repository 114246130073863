<div class="card mt-3 mb-3 shadow-sm">
    <div class="card-body">
        <h3>Cotação em Tempo Real</h3>
    </div>
</div>




<div class="row">
        <div class="col-md-2" *ngFor="let item of cotacoesVigente;let i=index">
            <div class="card">
                <div class="card-body">
                <h5 class="card-title">{{item.instituicao }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{item.moeda }}</h6>
                <p class="card-text">Compra/Venda: {{item.vlCotacaoCompra | currency:'BRL':true:'1.2-4' }} / {{item.vlCotacaoVenda | currency:'BRL':true:'1.2-4' }}</p>
                <!--<a href="#" class="btn btn-primary">Go somewhere</a>-->
                </div>
            </div>
        </div>

</div>



<!--
<div class="table-responsive">
    <table class="table table-bordered table-hover bg-white">
        <thead>
            <tr>
                <th>Instituição</th>
                <th>Moeda</th>
                <th>Valor Compra</th>
                <th>Valor Venda</th>
                <th>Data da Cotacao</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of cotacoesVigente">
                <td>{{item.instituicao }}</td>
                <td>{{item.moeda }}</td>
                <td>{{item.vlCotacaoCompra | currency:'BRL':true:'1.2-4' }}</td>
                <td>{{item.vlCotacaoVenda | currency:'BRL':true:'1.2-4' }}</td>
                <td>{{item.dtCotacao | date:'dd/MM/yyyy HH:mm'}}</td>
            </tr>
        </tbody>
    </table>
</div>
-->