import { Component, OnInit, OnDestroy, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UsuarioLogin } from 'src/app/models/usuario-login';
import { AuthJWTService } from 'src/app/services/auth-jwt.service';
import { AuthTokenService } from 'src/app/services/auth-token.service';
import { MessageService } from 'src/app/services/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public form: FormGroup;
  public disabled: boolean = false;

  public fields: { [index: string]: string } = {};
  public showErrors: boolean = false;

  constructor(
    private renderer: Renderer2,
    private fb: FormBuilder,
    private router: Router,
    private authTokenService: AuthTokenService,
    private authJWTService: AuthJWTService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {

    this.form = this.fb.group({
      email: ['', Validators.required],
      senha: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.authTokenService.signOut();

    this.fields["email"] = "E-mail";
    this.fields["senha"] = "Senha";
  }

  login() {

    if (this.form.invalid) {
      this.showErrors = true;
      return;
    };

    const usuarioLogin = new UsuarioLogin();
    usuarioLogin.email = this.form.value.email;
    usuarioLogin.senha = this.form.value.senha;

    this.authJWTService.login(usuarioLogin).subscribe(
      (data: any) => {
        this.authTokenService.onsignInEmitter.next(true);
        this.authTokenService.setToken(data.accessToken);
        this.authTokenService.setPerfil(data.descricaoPerfil);
        this.go();
      });
  }

  go() {
    this.router.navigate(['restrito']);
  }

}
