import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-model',
  templateUrl: './alert-model.component.html',
  styleUrls: ['./alert-model.component.scss']
})
export class AlertModelComponent implements OnInit {

  type: string = '';
  message: string = '';
  title: string = '';
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
