<h1 mat-dialog-title dialog-center>Cadastrar Novo Parâmetro</h1>
<mat-dialog-content class="mat-typography">
  <form class="example-form">

    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <mat-select [formControl]="tipoParametroFormControl" [(ngModel)]="tipoParametro" name="tipoParametro">
        <mat-option value="1">CPF</mat-option>
        <mat-option value="2">CNPJ</mat-option>
      </mat-select>

      <mat-error *ngIf="tipoParametroFormControl.hasError('required')">
        Selecione um tipo.
      </mat-error>

    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        type="text"
        placeholder="Nome Parâmetro"
        matInput
        [formControl]="nomeParametroFormControl"
        [matAutocomplete]="auto"
        [(ngModel)]="nomeParametro"
        name="nomeParametro"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event.option.value)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.jsonPropertyDescricao">
          {{ option.jsonPropertyDescricao }}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="nomeParametroFormControl.hasError('required')">
        Insira um nome de parâmetro para filtrar.
      </mat-error>

      <mat-error *ngIf="nomeParametroFormControl.hasError('incorrect')">
        Selecione um parâmetro válido.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        type="text"
        placeholder="TipoDado"
        matInput
        [disabled]="true"
        value="{{tipoDadoTela}}"
        #parametroInput
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Operador Lógico</mat-label>
      <mat-select [formControl]="operadorLogicoParametroFormControl" [(ngModel)]="operadorLogico" name="operadorLogico">
        <mat-option value="1">= (Igual)</mat-option>
        <mat-option value="2">!= (Diferente)</mat-option>
        <mat-option value="3">> (Maior)</mat-option>
        <mat-option value="4">< (Menor)</mat-option>
        <mat-option value="5">>= (Maior ou Igual)</mat-option>
        <mat-option value="6"><= (Menor ou Igual)</mat-option>
        <mat-option value="7">Between (Entre)</mat-option>
        <mat-option value="8">true (Verdadeiro)</mat-option>
        <mat-option value="9">false (Falso)</mat-option>
      </mat-select>

      <mat-error *ngIf="operadorLogicoParametroFormControl.hasError('required')">
        Selecione um tipo.
      </mat-error>

    </mat-form-field>

      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Valor 1</mat-label>
        <input matInput placeholder="" value="" [(ngModel)]="valor1" name="valor1">
      </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Valor 2</mat-label>
          <input matInput placeholder="" value="" [(ngModel)]="valor2" name="valor2">
        </mat-form-field>

        <mat-form-field class="" appearance="fill">
          <mat-label>Score</mat-label>
          <input [formControl]="scoreFormControl" type="number" matInput placeholder="" value="" [(ngModel)]="score" name="score">
        </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>Cancelar</button>
  <button *ngIf="validation() == false" mat-button [mat-dialog-close]="true" (click)="saveParametro()">Salvar</button>
</mat-dialog-actions>
