import { Injectable } from '@angular/core';

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { LocalStorageUtils } from 'src/app/utils/localstorage';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const localStorageUtils = new LocalStorageUtils();
        const accessToken = localStorageUtils.obterTokenUsuario();

        if (accessToken) {
            request = request.clone({
                headers: request.headers.set(
                    'Authorization',
                    'Bearer ' + accessToken
                ),
            });

            request = request.clone({
                headers: request.headers.set('Accept', 'application/json'),
            });
        }

        return next.handle(request);
    }
}
