import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { UsuarioLogin } from '../models/usuario-login';
import { environment } from 'src/environments/environment';
import { LocalStorageUtils } from '../utils/localstorage';

@Injectable({
    providedIn: 'root'
})

export class AuthJWTService {
    public localStorageUtils = new LocalStorageUtils();

    constructor(
        private http: HttpClient
    ) { }

    login(usuarioLogin: UsuarioLogin) {
        return this.http.post<string>(environment.apiUrl + '/login/autenticar', usuarioLogin);
    }

    logout() {
        this.localStorageUtils.limparDadosLocais();
    }

    decodeJwt(): any {
        return this.localStorageUtils.decodeJwt();
    }
}
