<div class="modal-header">
    <h4 class="modal-title text-{{type}}">{{title}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body">
    <div style="font-size: 16px;word-break: break-word;" [innerHTML]="message"></div>
</div>
<div class="modal-footer">
    <button style="color: #fff;" class="btn btn-{{type}}" (click)="bsModalRef.hide()">
        <strong>OK</strong>
    </button>
</div>
