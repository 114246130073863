import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {

  public form: FormGroup;
  public disabled: boolean = false;

  public fields: { [index: string]: string } = {};
  public showErrors: boolean = false;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private messageService: MessageService,
  ) {

    this.form = this.fb.group({
      senhaAtual: ['', Validators.required],
      novaSenha: ['', Validators.required],
      confirmeNovaSenha: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.fields["senhaAtual"] = "Senha atual";
    this.fields["novaSenha"] = "Nova senha";
    this.fields["confirmeNovaSenha"] = "Confirme nova senha";
  }

  alterarSenha() {

    if (this.form.invalid) {
      this.showErrors = true;
      return;
    };

    const params = {
      currentPassword: this.form.value.senhaAtual,
      newPassword: this.form.value.novaSenha,
      confirmNewPassword: this.form.value.confirmeNovaSenha
    };

    this.loginService.alterarSenha(params).subscribe(
      (data: any) => {
        this.messageService.success('Senha alterada com sucesso.');
        this.form.reset();
      });
  }



}
