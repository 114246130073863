import { Component, OnInit, Input, Inject } from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import { MatrizRisco } from 'src/app/models/matriz-risco';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatrizRiscoService } from 'src/app/services/matriz-risco.service';
import { MatrizRiscoItem } from 'src/app/models/matriz-risco-item';
import { MessageService } from 'src/app/services/message.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              private matrizRiscoService: MatrizRiscoService,
              private messageService: MessageService) { }

  matrizItens: MatrizRiscoItem[] = [];
  id: number = 0;
  score: number = 0;
  cpf: string = "";
  cnpj: string = "";
  nome: string = "";
  empresa: string = "";
  compliance: boolean = true;
  matrizAvaliada: boolean = false;
  limiteFinanceiro: number = 0.00;
  retornoString: string = "";
  habilitadoRadar: string = "X";
  habilitadoRadarFormControl = new FormControl('', [ Validators.required ]);
  scoreRadar: number = 0;
  isTouched: boolean = false;

  ngOnInit(): void {
    this.id = this.data.id;
    this.score = this.data.score;
    this.nome = this.data.nome;
    this.empresa = this.data.empresa;
    this.cnpj = this.data.cnpj;
    this.cpf = this.data.cpf;
    this.matrizAvaliada = this.data.matrizAvaliada;
    this.compliance = this.data.compliance;
    this.limiteFinanceiro = this.data.limiteFinanceiro;
    this.getMatrizItem(this.id);
    if(this.cnpj == '' || this.compliance == false){
      this.isTouched = true;
    }

    this.habilitadoRadarFormControl.valueChanges.subscribe((value) => {
      if(value == 'N' || value == 'S' && this.cnpj != ''){
        this.scoreRadar = 16;
      }else{
        this.scoreRadar = 0;
      }

      if(value != undefined && value != ''){
        this.isTouched = true;
      }
    });
  }

  getMatrizItem(id: any){
    this.matrizRiscoService.getItem(id).subscribe(
      (retorno: MatrizRiscoItem[]) => {
        this.matrizItens = retorno;

        console.log(this.matrizAvaliada);
        console.log(this.isTouched);
      },
      () => {},
      () => {}
    ).add();
  }

  putAvaliaMatrizItem(id: any, aprova: boolean, compliance: boolean, radar: string){
    this.matrizRiscoService.putAvaliaItem(id, aprova, compliance, radar).subscribe(
      (retorno: string) => {
        this.retornoString = retorno;
      },
      () => {},
      () => {}
    ).add();
  }

  }

