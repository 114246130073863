import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CotacaoService } from 'src/app/services/cotacao.service';

@Component({
  selector: 'app-cotacao-visualizar-page',
  templateUrl: './cotacao-visualizar-page.component.html',
  styleUrls: ['./cotacao-visualizar-page.component.scss']
})
export class CotacaoVisualizarPageComponent implements OnInit {

  public cotacao: any = {};

  constructor(private cotacaoService: CotacaoService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    /*const id = this.route.snapshot.params['id'];

    this.cotacaoService.obterCotacaoFechamento(id).subscribe((cotacao: any) => {
      this.cotacao = cotacao;
    });
    */
  }
}
