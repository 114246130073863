import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NaturezaService {

    constructor(private http: HttpClient) { }

    obterNaturezas() {
        return this.http.get(environment.apiUrl + '/naturezas');
    }

    obterNatureza(id: any) {
        return this.http.get(environment.apiUrl + '/naturezas/' + id);
    }
}
