import { Component, OnInit } from '@angular/core';
import { CotacaoService } from 'src/app/services/cotacao.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-cotacao-vigente-page',
  templateUrl: './cotacao-tempo-real-page.component.html',
  styleUrls: ['./cotacao-tempo-real-page.component.scss']
})



export class CotacoesTempoRealPageComponent implements OnInit {

  public cotacoesVigente: any[] = [];

  constructor(
    private cotacaoService: CotacaoService,
    private route: ActivatedRoute, private router: Router
    ) {

      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  ngOnInit(): void {
    this.list();



  }

  list() {
    this.cotacaoService.obterCotacoesVigente()
      .subscribe((result: any) => {
        this.cotacoesVigente = result;
      });
  }



}

