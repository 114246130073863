import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { MatrizRisco } from '../../models/matriz-risco';
import { MatrizRiscoService } from '../../services/matriz-risco.service';
import { DialogContentComponent } from '../dialog-content/dialog-content.component';
import { getFile } from '../../services/aws.service';

@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.scss'],
})
export class FinanceiroComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private matrizRiscoService: MatrizRiscoService,
    private router: Router
  ) {}

  matrizes: MatrizRisco[] = [];

  private _filtroLista: string = '';
  matrizesFiltradas: any = this.matrizes;

  public get filtroLista(): string {
    return this._filtroLista;
  }

  public set filtroLista(value: string) {
    this._filtroLista = value;
    this.matrizesFiltradas = this.filtroLista
      ? this.filtrarLista(this.filtroLista)
      : this.matrizes;
  }

  filtrarLista(filtrarPor: string): any {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.matrizes.filter(
      (matrizes) =>
        matrizes.nome.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        matrizes.cpf.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        matrizes.cnpj.toLocaleLowerCase().indexOf(filtrarPor) !== -1 ||
        matrizes.nomeEmpresa.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  downloadDocument(cpf: string, cnpj: string, nome: string) {
    let documentName;

    if (cnpj) {
      documentName =
        'CNPJ' + cnpj.replace(/\D/g, '') + ' - Contrato Social.pdf';
    } else {
      documentName = 'CPF' + cpf.replace(/\D/g, '') + ' - Imposto de Renda.pdf';
    }

    getFile(documentName);
  }

  ngOnInit(): void {
    this.getAll();
  }

  openDialog(
    id: number,
    score: number,
    cpf: string,
    cnpj: string,
    nome: string,
    empresa: string,
    matrizAvaliada: boolean,
    compliance: boolean,
    limiteFinanceiro: number
  ) {
    const dialogRef = this.dialog.open(DialogContentComponent, {
      data: {
        id: id,
        score: score,
        cpf: cpf,
        cnpj: cnpj,
        nome: nome,
        empresa: empresa,
        matrizAvaliada: matrizAvaliada,
        compliance: compliance,
        limiteFinanceiro: limiteFinanceiro,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);

      const navigationExtras: NavigationExtras = {
        skipLocationChange: true,
      };

      if (result == true) {
        this.router.navigate(['/restrito/financeiro']).then(() => {
          window.location.reload();
        });
      }
    });
  }

  getAll() {
    this.matrizRiscoService
      .getall(false)
      .subscribe(
        (retorno: MatrizRisco[]) => {
          this.matrizes = retorno;
          this.matrizesFiltradas = retorno;
        },
        () => {},
        () => {}
      )
      .add();
  }
}
