import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';
import { MatrizRisco } from '../models/matriz-risco';
import { MatrizRiscoItem } from '../models/matriz-risco-item';
import { Observable, map, take } from 'rxjs';
import { MatrizRiscoParametro } from '../models/matriz-risco-parametro';
import { Parameter } from '../models/parameter';

@Injectable({
    providedIn: 'root'
})
export class MatrizRiscoService {


    constructor(private http: HttpClient) { }

    getall(compliance: boolean) {
        return this.http.get<MatrizRisco[]>(environment.apiUrl + '/matrizrisco/matriz/' + compliance);
    }

    getItem(id: any) {
      return this.http.get<MatrizRiscoItem[]>(environment.apiUrl + '/matrizrisco/' + id);
  }

  putAvaliaItem(id: number, aprova: boolean, compliance: boolean, radar: string): Observable<string> {
    return this.http.put(environment.apiUrl + '/matrizrisco/' + id + ',' + aprova + ',' + compliance + ',' + radar, null, { responseType: 'text' })
      .pipe(
        map((response: string) => {
          return response;
        })
      );
  }

  getallParametros() {
    return this.http.get<MatrizRiscoParametro[]>(environment.apiUrl + '/matrizrisco/parametro/');
}

getallParametrosNome() {
  return this.http.get<Parameter[]>(environment.apiUrl + '/matrizrisco/parametro/nome/');
}

putInativaParametro(id: number): Observable<string> {
  return this.http.put(environment.apiUrl + '/matrizrisco/parametro/' + id, null, { responseType: 'text' })
    .pipe(
      map((response: string) => {
        return response;
      })
    );
}

public post(parametro: MatrizRiscoParametro): Observable<string>{
  return this.http
  .post<string>(environment.apiUrl+'/matrizrisco/parametro/', parametro)
  .pipe(map((response: string) => { return response; } ));
  }
}

