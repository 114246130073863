import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CotacaoService {

    constructor(private http: HttpClient) { }

    obterTiposDeTransacoes() {

        let result: any[] = [];
        result.push({ id: 0, nome: 'Envio' });
        result.push({ id: 1, nome: 'Recebimento' });
        return result;

    }

    obterCotacoes() {
        return this.http.get(environment.apiUrl + '/cotacoes');
    }

    obterCotacao(id: any) {
        return this.http.get(environment.apiUrl + '/cotacoes/' + id);
    }

    obterMinimo(id: any) {
        return this.http.get(environment.apiUrl + '/cotacoes/moeda/' + id);
    }

    criar(cotacao: any) {
        return this.http.post(environment.apiUrl + '/cotacoes', cotacao);
    }

    calcular(data: any) {
        return this.http.post(environment.apiUrl + '/cotacoes/calculate', data);
    }

    concluir(data: any) {
        return this.http.post(environment.apiUrl + '/cotacoes/done', data);
    }

    declinar(data: any) {
        return this.http.post(environment.apiUrl + '/cotacoes/denied', data);
    }

    salvardadosbancarios(data: any) {
        return this.http.post(environment.apiUrl + '/cotacoes/savebankaccount', data);
    }

    obterMotivosDeclinio() {
        return this.http.get(environment.apiUrl + '/cotacoes/motivos/declinio');
    }

    obterCotacoesVigenteOffLine() {
        return this.http.get(environment.apiUrl + '/cotacoes/vigenteoffline');
    }

    obterCotacoesVigente() {
        return this.http.get(environment.apiUrl + '/cotacoes/vigente');
    }

    enviarEmail(id: any) {
        return this.http.get(environment.apiUrl + '/cotacoes/fechamento/enviaemail/' + id);
    }

    //Fechamento
    obterCotacaoFechamento(id: any) {
        return this.http.get(environment.apiUrl + '/cotacoes/fechamento/' + id);
    }

    notificarQuedaAlta(data: any) {
        return this.http.post(environment.apiUrl + '/cotacoes/notificar-queda-alta', data);
    }

    obterCotacoesPorCliente(currentPage: any, pageSize: any) {

        const params = {
            currentPage,
            pageSize
        };

        return this.http.get(environment.apiUrl + '/cotacoes/allByClienteOrParceiro', { params });
    }

    obterCotacoesPor(dataInicioFechamento: any, dataFimFechamento: any, tipoTransacaoId: any, clienteId: any, currentPage: any, pageSize: any) {

        const params = {
            currentPage,
            pageSize
        };

        return this.http.get(environment.apiUrl + '/cotacoes/allBy', { params });
    }

    salvarCotacaoVigente(data: any) {
        return this.http.post(environment.apiUrl + '/cotacoes/vigenteoffline', data);
    }
}