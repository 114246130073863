// export const environment = {
//   production: false,
//   apiUrl: 'https://localhost:7074/api',
//   TimeLimitD0: '14:00',
//   TimeLimitD1D2: '16:30',
//   ambientehomologacao: true
// };


export const environment = {
  production: true,
  apiUrl: 'https://api.zebra.tec.br/v1/api', 
  TimeLimitD0: '14:00',
  TimeLimitD1D2: '16:30',
  ambientehomologacao: false
};
