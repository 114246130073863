import { Component, OnInit, ViewChild  } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-cotacao-page',
  templateUrl: './cotacao-page.component.html',
  styleUrls: ['./cotacao-page.component.scss']
})
export class CotacaoPageComponent implements OnInit {
  
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;

  constructor() { }

  ngOnInit(): void {
  }

  selectTab(tabId: number) {
    if (this.staticTabs?.tabs[tabId]) {
      this.staticTabs.tabs[tabId].active = true;
    }
  }

}
