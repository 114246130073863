<form class="row" [formGroup]="form" (submit)="concluir()">
  <!--<validation-summary [form]="form" [fields]="fields" [showErrors]="showErrors"></validation-summary>-->
  <div class="col">



    <div class="row mt-2 mb-2">
      <div class="col-sm-12">
        <input [ngModelOptions]="{standalone: true}" [(ngModel)]="clienteSearch" [typeahead]="clienteSuggestions$"
          (typeaheadLoading)="clienteChangeTypeaheadLoading($event)"
          (typeaheadOnSelect)="clienteTypeaheadOnSelect($event)" (typeaheadOnBlur)="clienteTypeaheadOnBlur($event)"
          [typeaheadWaitMs]="300" [typeaheadOptionsLimit]="7" [typeaheadOptionField]="'nome'" placeholder="Cliente: Pesquise por nome, e-mail, cpnj ou cpf"
          class="form-control">
      </div>
    </div>

    <div class="row mb-2" *ngIf="form.value.clienteId !== ''">
      <div class="col-sm-6">
        <select formControlName="naturezaId" class="form-select mb-2" id="naturezaId" (change)="changeNatureza()">
          <option value="">Natureza</option>
          <option *ngFor="let e of naturezas" [ngValue]="e.id">
            {{ e.numero }} - {{ e.nome }}
          </option>
        </select>
      </div>
      <label for="liquidacaoId" class="col-sm-3 col-form-label">Liquidação</label>
      <div class="col-sm-3">
        <select formControlName="liquidacaoId" class="form-select mb-2" id="liquidacaoId" (change)="changeLiquidacao()">
          <option *ngFor="let e of liquidacoes" [ngValue]="e.id">
            {{ e.nome }}
          </option>
        </select>
      </div>
    </div>


    <!-- Se for envio, só mostra banco offline se não tiver integração com API-->
    <div class="row mb-2" *ngIf="form.value.clienteId !== '' && form.value.naturezaId !== '' && form.value.tipoTransacao == 0">
      <div class="col-sm-3">
        <input type="checkbox" formControlName="bancoOffline" value="true" (click)="clearCalcular()"> Banco offline?
      </div>
      <div class="col-sm-6">
        <select formControlName="bancoId" class="form-select mb-2" id="bancoId" *ngIf="form.value.bancoOffline == true" (change)="changeBanco()">
          <option value="0" selected>Selecione um banco</option>
          <option *ngFor="let e of bancos" [ngValue]="e.id">
            {{ e.nome }}
          </option>
        </select>

      </div>
      <div class="col-sm-3">
        <input type="text" class="form-control" aria-label="Text input with dropdown button" formControlName="ValorManualCotacao" *ngIf="form.value.bancoOffline == true"
        currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision : 4 }">
      </div>
    </div>
    <!-- Fim da escolha do banco offline -->

    <!-- Se for receber, temos que escolher de que banco de dados -->
    <div class="row mb-2" *ngIf="form.value.clienteId !== '' && form.value.naturezaId !== '' && form.value.tipoTransacao == 1">
      <div class="col-sm-6">
        <select formControlName="bancoId" class="form-select mb-2" id="bancoId" (change)="changeBanco()">
          <option value="0" selected>Selecione um banco</option>
          <option *ngFor="let e of bancos" [ngValue]="e.id">
            {{ e.nome }}
          </option>
        </select>

      </div>
      <div class="col-sm-6" *ngIf="form.value.bancoOffline == true">
        <input type="text" class="form-control" aria-label="Text input with dropdown button" formControlName="ValorManualCotacao"
        currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',precision : 4 }">
      </div>

    </div>
    <!-- Fim da escolha do banco de receber -->

    <div class="row mb-2 danger" *ngIf="form.value.clienteId !== '' && form.value.naturezaId !== ''">
      <div class="col-sm-3">
        <select formControlName="moedaId" class="form-select mb-2" id="moedaId" (change)="changeMoeda()">
          <option value="">Moeda</option>
          <option *ngFor="let e of moedas" [ngValue]="e.id">
            {{ e.nome }} ({{e.codigo}})
          </option>
        </select>
      </div>
      <div class="col-sm-9">
        <input type="text" class="form-control" aria-label="Text input with dropdown button" formControlName="valor"
          currencyMask>
      </div>
    </div>

    <div class="row mb-2" *ngIf="form.value.clienteId !== '' && form.value.naturezaId !== '' && form.value.valor !== ''">
      <div class="col">
        <div class="d-grid gap-2">
          <button type="button" class="btn btn-success" (click)="calcularBotao()">Calcular</button>
        </div>
      </div>
    </div>



    <div *ngIf="form.value.exibeCalculo == true">
      <div class="row mb-2">
        <label for="cotacaoComercial" class="col-sm-6 col-form-label">Cotação
          (Spread: {{cotacao.percentualSpread}}%)</label>
        <div class="col-sm-2">
          <input type="text" readonly class="form-control-plaintext text-end" id="validade"
            [value]="tempoNovoCalculo + 's'">
        </div>
        <div class="col-sm-4">
          <input type="text" readonly class="form-control-plaintext text-end" id="cotacaoComercial"
            [value]="cotacao.valorCotacao | currency:'BRL':true:'1.4-4'">
        </div>
      </div>

      <div class="row mb-2">
        <label for="cotacaoComercial" class="col-sm-6 col-form-label">Valor da Operação</label>

        <div class="col-sm-6">
          <input type="text" readonly class="form-control-plaintext text-end" id="valorOperacao"
            [value]="cotacao.valorCotacao*cotacao.valorCotado | currency : 'BRL':true:'1.4-4'">
        </div>
      </div>

      <div class="row mb-2">
        <label for="iof" class="col-sm-6 col-form-label">IOF ({{cotacao.percentualIof}}%)</label>
        <div class="col-sm-6">
          + <input type="text" readonly class="form-control-plaintext text-end" id="iof"
            [value]="cotacao.iof | currency : 'BRL':true:'1.4-4'">
        </div>
      </div>

      <div class="row mb-2">
        <label for="iof" class="col-sm-6 col-form-label">IR ({{cotacao.percentualIr}}%)</label>
        <div class="col-sm-6">
         + <input type="text" readonly class="form-control-plaintext text-end" id="ir"
            [value]="cotacao.ir | currency : 'BRL':true:'1.4-4'">
        </div>
      </div>

      <div class="row mb-2">
        <label for="taxasExternas" class="col-sm-6 col-form-label">Taxa ADM</label>
        <div class="col-sm-6">
         + <input type="text" readonly class="form-control-plaintext text-end" id="taxasExternas"
            [value]="cotacao.taxasExternas | currency : 'BRL'">
        </div>
      </div>

      <div class="row mb-2">
        <label for="valorEfetivo" class="col-sm-6 col-form-label">Valor Efetivo Total (VET)</label>
        <div class="col-sm-6">
          <input type="text" readonly class="form-control-plaintext text-end" id="valorEfetivo"
            [value]="cotacao.valorEfetivo | currency : 'BRL':true:'1.12-12'">
        </div>
      </div>

      <div class="row mb-2">
        <label for="valorTotal" class="col-sm-6 col-form-label"><b>Valor total</b></label>
        <div class="col-sm-6">
         = <input type="text" readonly class="form-control-plaintext text-end" id="valorTotal"
            [value]="cotacao.valorTotal | currency : 'BRL'">
        </div>
      </div>

      <div class="row mb-2" *ngIf="(PassouHoraD0 && form.value.liquidacaoId == 1) || (PassouHoraD1D2 && form.value.liquidacaoId > 1) || form.value.fechamentoForaHorario">
        <label for="fechamentoForaHorario" class="col-sm-6 col-form-label"><b>Fazer o fechamento fora do horário limite?</b></label>
        <div class="col-sm-6">
          <input type="checkbox" formControlName="fechamentoForaHorario" value="true" (click)="confirmarFechamento()"> &nbsp;&nbsp; <b>SIM</b>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <div class="d-grid gap-2">
            <button type="submit" [disabled]="this.timeout"  class="btn btn-success">CONFIRMAR O FECHAMENTO</button>
          </div>
        </div>
      </div>


      <div class="row mb-2">
        <div class="col-sm-12">
          <select formControlName="motivoId" class="form-select mb-2" id="motivoId">
            <option value="0">Escolha um motivo para o declinio</option>
            <option *ngFor="let e of motivos" [ngValue]="e.id">
              {{ e.motivo }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-danger" (click)="declinar()">DECLINAR COTAÇÃO</button>
          </div>
        </div>
      </div>


    </div>




  </div>
</form>
