import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MoedaService } from 'src/app/services/moeda.service';
import { CotacaoService } from 'src/app/services/cotacao.service';
import { NaturezaService } from 'src/app/services/natureza.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { LiquidacaoService } from 'src/app/services/liquidacao.service';
import { Observable, timer } from 'rxjs';
import { BancoService } from 'src/app/services/banco.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cotacao',
  templateUrl: './cotacao.component.html',
  styleUrls: ['./cotacao.component.scss']
})
export class CotacaoComponent implements OnInit, OnDestroy {

  @Input() public tipoTransacao: number = 0;

  public validade: number = 30;
  public tempoNovoCalculo: number = this.validade;
  public moedas: any[] = [];
  public bancos: any[] = [];
  public naturezas: any[] = [];
  public motivos: any[] = [];
  public liquidacoes: any[] = [];
  public cotacao: any = {};
  public form: FormGroup;
  public PassouHoraD0: boolean = false;
  public PassouHoraD1D2: boolean = false;
  public timeout: boolean = false;
  public bancoId: number = 0;

  public fields: { [index: string]: string } = {};
  public showErrors: boolean = false;

  private myInterval: any;
  private autoCalculate: boolean = true;
  


  public clienteSearch?: string;
  public clienteSuggestions$?: Observable<any[]>;
  public clienteTypeaheadLoading: boolean = false;
  public clienteTypeaheadNoResults: boolean = false;

  constructor(
    private bancoService: BancoService,
    private moedaService: MoedaService,
    private cotacaoService: CotacaoService,
    private naturezaService: NaturezaService,
    private clienteService: ClienteService,
    private liquidacaoService: LiquidacaoService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {

    this.form = this.fb.group({
      tipoTransacao: [0, Validators.required],
      naturezaId: [, Validators.required],
      clienteId: ['', Validators.required],
      liquidacaoId: [, Validators.required],
      bancoId: [0],
      motivoId: [0],
      bancoOffline: false,
      bancoAPI: false,
      exibeCalculo: false,
      fechamentoForaHorario: false,
      moedaId: [, Validators.required],
      valor: ['', Validators.required],
      ValorManualCotacao: [''],
    });
  }

  ngOnDestroy() {
  }

  ngOnInit(): void {
    this.form.controls["bancoOffline"].setValue(true); // deixando fixo para nesta etapa testar
    this.carregarClientes();
    this.carregarNaturezas();
    this.carregarModedas();
    this.carregarLiquidacoes();
    this.carregarMotivos();
    this.carregarBancosOffline(this.tipoTransacao);

    this.form.controls["tipoTransacao"].setValue(this.tipoTransacao);
    this.form.controls["motivoId"].setValue(0)
    //this.verificaLimiteHorario();

  }

  verificaLimiteHorario()
  {
    var now: Date = new Date();
    var hora = now.getHours() + ":" + now.getMinutes();
    var TimeLimitD0 = environment.TimeLimitD0
    console.log("Verificando horario limite ... ");
    console.log(hora);
    console.log(TimeLimitD0);

    // Verificamos se passou da hora das cotações
    if (hora >= TimeLimitD0)
    {
      this.PassouHoraD0 = true;
      console.log("Passou da hora ... ");
    }
    else
    {
      this.PassouHoraD0 = false;
    }

    var TimeLimitD1D2 = environment.TimeLimitD1D2
    
    // Verificamos se passou da hora das cotações
    if (hora >= TimeLimitD1D2)
    {
      this.PassouHoraD1D2 = true;
      console.log("Passou da hora ... ");
    }
    else
    {
      this.PassouHoraD1D2 = false;
    }

  }

  confirmarFechamento()
  {
    if (this.form.controls["fechamentoForaHorario"].value == true)
    {
      this.PassouHoraD0 = true;
      this.PassouHoraD1D2 = true;
      this.form.controls["fechamentoForaHorario"].setValue(false);
    }
    else
    {
      this.PassouHoraD0 = false;
      this.PassouHoraD1D2 = false;
      this.form.controls["fechamentoForaHorario"].setValue(true);
    }
    
  }

  carregarMotivos() {
    this.cotacaoService.obterMotivosDeclinio().subscribe((motivos: any) => {
      this.motivos = motivos;
      this.clear();
    });
  }

  carregarNaturezas() {
    this.naturezaService.obterNaturezas().subscribe((naturezas: any) => {
      this.naturezas = naturezas;
      this.clear();
    });
  }

  carregarLiquidacoes() {
    this.liquidacaoService.obterLiquidacoes().subscribe((liquidacoes: any) => {
      this.liquidacoes = liquidacoes;
      this.clear();
    });
  }

  carregarClientes() {
    this.clienteSuggestions$ = Observable.create((observer: any) => {
      this.clienteService.obterClientesPorPhrase(this.clienteSearch).subscribe((result: any) => {
        observer.next(result);
      })
    });
  }

  clienteChangeTypeaheadLoading(e: boolean): void {
    this.clienteTypeaheadLoading = e;
  }

  clienteTypeaheadOnSelect(e: any): void {
    this.form.controls["clienteId"].setValue(e.item.id);
  }

  clienteTypeaheadOnBlur(e: any): void {
    if (!e || !e.item) this.form.controls["clienteId"].setValue('');
  }

  carregarModedas() {
    this.moedaService.obterMoedas().subscribe((moedas: any) => {
      this.moedas = moedas;
      this.clear();
    });
  }

  carregarBancosOffline(tipo: number) {

    // Tratamento para recebimento
    if (tipo == 0) {
      this.bancoService.obterBancosOffline().subscribe((bancos: any) => {
        this.bancos = bancos;
      });
    }

    if (tipo == 1) {
      this.bancoService.obterBancosAtivos().subscribe((bancos: any) => {
        this.bancos = bancos;
      });
    }
  }

  // Função específica para o receber
  changeBanco() {
    let bancoEscolhido = this.form.controls["bancoId"].value;
    this.bancoService.obterBanco(bancoEscolhido).subscribe((banco: any) => {
      if (banco.api)
      {
        this.form.controls["bancoOffline"].setValue(false);
      }
      else
      {
        this.form.controls["bancoOffline"].setValue(true);
      }
    }); 

    this.form.controls["ValorManualCotacao"].setValue('');

    this.clearCalcular();
  }

  changeMoeda() {
    this.clearCalcular();
    //this.calcular();
  }

  changeValor() {
    this.clearCalcular();
    //this.calcular();
  }

  changeLiquidacao() {
    this.clearCalcular();
    //this.calcular();
  }

  changeNatureza() {
    this.clearCalcular();
    //this.calcular();
  }

  calcularBotao()
  {
    //console.log("Botao ... ");
    this.calcular();

  }

  calcular() {
    this.verificaLimiteHorario();
    debugger;
    this.form.controls["motivoId"].setValue(0)
    if (this.form.invalid) {
      this.showErrors = true;
      return;
    };

    if (this.form.controls["valor"].value >= 300000.01) {
      this.showErrors = true;
      return;
    }


    if (this.form.controls["bancoId"].value == "0" && this.form.controls["bancoOffline"].value == true) {
      this.showErrors = true;
      return;
    }

    // Tratamento valorManual
    if (this.form.controls["ValorManualCotacao"].value == "") {
      this.form.controls["ValorManualCotacao"].setValue(0);
    }

    // Tratamento para recebimento
    //if (this.form.controls["tipoTransacao"].value == 1) {
      //this.form.controls["bancoOffline"].setValue(true);
    //}

    this.cotacaoService.calcular(this.form.value).subscribe((data: any) => {
      this.cotacao = data;
      this.tempoNovoCalculo = this.validade;

      this.processarContagemRegressiva();
    }, (err) => {
      this.autoCalculate = false;
    });

    this.form.controls["exibeCalculo"].setValue(true);

  }

  concluir() {
    if (this.form.invalid) {
      this.showErrors = true;
      return;
    };

    if (this.form.controls["bancoId"].value == "0" && this.form.controls["bancoOffline"].value == true) {
      this.showErrors = true;
      return;
    }

    this.cotacaoService.concluir(this.form.value).subscribe((data: any) => {
      this.cotacao = data;
      this.router.navigate(['/restrito/cotacao/' + this.cotacao.id]);
    });
  }

  declinar()
  {
    if (this.form.controls["motivoId"].value == 0)
    {
      this.showErrors = true;
      return;
    }

    //debugger;
    this.cotacaoService.declinar(this.form.value).subscribe((data: any) => {
      //this.cotacao = data;
      this.clear();
    });

    
  }

  clear() {
    //return;
    //this.form.reset();
    this.form.controls["exibeCalculo"].setValue(false);
    this.cotacao = {};
    this.form.controls["valor"].setValue('');
    this.form.controls["naturezaId"].setValue('');
    this.form.controls["motivoId"].setValue('0');
    this.form.controls["clienteId"].setValue('');
    this.form.controls["liquidacaoId"].setValue('');
    this.form.controls["ValorManualCotacao"].setValue('');
    this.form.controls["bancoId"].setValue('0');
    this.form.controls["moedaId"].setValue('');

    if (this.moedas.length > 0) this.form.controls["moedaId"].setValue(this.moedas[0].id);
    if (this.liquidacoes.length > 0) this.form.controls["liquidacaoId"].setValue(this.liquidacoes[0].id);

    this.form.controls['valor'].valueChanges.subscribe(change => {
      setTimeout(() => {
        this.changeValor();
      }, 1000);
    });

    

  }

  clearCalcular() {
    this.form.controls["exibeCalculo"].setValue(false);
  }

  processarContagemRegressiva() {
    //if (this.autoCalculate || this.myInterval !== undefined) return;
    this.timeout = false;
    if (this.myInterval !== undefined) return;
    this.myInterval = setInterval(() => {
      if (this.tempoNovoCalculo === 0) {
        //this.calcular();
        this.timeout = true;
        return;
      }
      this.tempoNovoCalculo--;
    }, 1000);
  }

}
