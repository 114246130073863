<app-navbar></app-navbar>

<div class="container h-100">
  <div class="row justify-content-center align-items-center h-100">
    <div class="col col-sm-6 col-md-6 col-lg-5 col-xl-4">
      <h1>Esqueci minha senha</h1>
      <p>Nao lembra a senha? Nao tem problema,
        digite seu e-mail registrado abaixo para
        receber instruções de redefinição de senha.</p>
      <form [formGroup]="form" (submit)="send()">
        <!--<validation-summary [form]="form" [fields]="fields" [showErrors]="showErrors"></validation-summary>-->
        <div class="mb-3">
          <label for="email" class="form-label">Informe seu e-mail</label>
          <input maxlength="255" autofocus type="email" class="form-control" id="email" placeholder="nome@example.com"
            formControlName="email">
        </div>
        <button class="w-100 btn btn-lg btn-primary" type="submit">Enviar</button>
        <p class="text-center mt-3">
          <a class="btn btn-link" [routerLink]="[ '/' ]">Voltar para o login</a>
        </p>
      </form>
    </div>
    <div class="col text-center align-middle bg-sign-in d-none d-lg-flex d-xl-flex">
      <img src="../../../assets/min-logo.svg" alt="ZEBRA">
    </div>
  </div>
</div>