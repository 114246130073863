import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CotacaoService } from 'src/app/services/cotacao.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { AuthTokenService } from 'src/app/services/auth-token.service';
import { Observable, Subject, timer } from 'rxjs';
import { ClienteService } from 'src/app/services/cliente.service';

@Component({
  selector: 'app-cotacoes-page',
  templateUrl: './cotacoes-page.component.html',
  styleUrls: ['./cotacoes-page.component.scss']
})
export class CotacoesPageComponent implements OnInit {

  public cotacoes: any[] = [];
  public clientes: any[] = [];
  public tiposDeTransacoes: any[] = this.cotacaoService.obterTiposDeTransacoes();

  public form: FormGroup;

  //pagination
  public currentPage: number = 1;
  public totalItems: number = 0;
  public itemsPerPage: number = 25;
  public maxSize: number = 25;
  //end pagination

  //typeahead
  public clienteSearch?: string;
  public clienteSuggestions$?: Observable<any[]>;
  public clienteTypeaheadLoading: boolean = false;
  public clienteTypeaheadNoResults: boolean = false;
  //end typeahead

  constructor(private fb: FormBuilder,
    private cotacaoService: CotacaoService,
    private clienteService: ClienteService) {
    this.form = this.fb.group({
      dataInicioFechamento: [''],
      dataFimFechamento: [''],
      tipoTransacaoId: [''],
      clienteId: ['']
    });
  }

  ngOnInit(): void {
    this.carregarClientes();
    this.list();
  }

  search() {
    this.currentPage = 1;
    this.list();
  }

  list() {

    let dataInicioFechamento: any = this.form.controls["dataInicioFechamento"].value;
    let dataFimFechamento: any = this.form.controls["dataFimFechamento"].value;
    let tipoTransacaoId: any = this.form.controls["tipoTransacaoId"].value;
    let clienteId: any = this.form.controls["clienteId"].value;

    this.cotacaoService.obterCotacoesPor(dataInicioFechamento, dataFimFechamento, tipoTransacaoId, clienteId, this.currentPage, this.itemsPerPage)
      .subscribe((result: any) => {
        this.cotacoes = result.itens;
        this.totalItems = result.total;
      });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    this.list();
  }

  carregarClientes() {
    this.clienteSuggestions$ = Observable.create((observer: any) => {
      this.clienteService.obterClientesPorPhrase(this.clienteSearch).subscribe((result: any) => {
        observer.next(result);
      })
    });
  }

  clienteChangeTypeaheadLoading(e: boolean): void {
    this.clienteTypeaheadLoading = e;
  }

  clienteTypeaheadOnSelect(e: any): void {
    this.form.controls["clienteId"].setValue(e.item.id);
  }

  clienteTypeaheadOnBlur(e: any): void {
    if (!e || !e.item) this.form.controls["clienteId"].setValue('');
  }

}
