<h1 mat-dialog-title dialog-center>Matriz de Risco - Detalhe</h1>
<mat-dialog-content class="mat-typography">
  <mat-card>
    <mat-card-content>Nome: {{nome}}</mat-card-content>
    <mat-card-content>CPF: {{cpf}}</mat-card-content>
    <mat-card-content>Empresa: {{empresa}}</mat-card-content>
    <mat-card-content>CNPJ: {{cnpj}}</mat-card-content>
  </mat-card>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Propriedade</th>
        <th>Score</th>
        <th>Parametro Aplicado</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let matrizItem of matrizItens">
        <td>{{ matrizItem.jsonProperty}}</td>
        <td>{{ matrizItem.score }}</td>
        <td *ngIf="matrizItem.parametroAplicado == true"> Sim</td>
        <td *ngIf="matrizItem.parametroAplicado == false || matrizItem.parametroAplicado == null"> Não</td>
      </tr>
    </tbody>
  </table>

  <mat-form-field *ngIf="cnpj != '' && compliance == true && matrizAvaliada == false">
    <mat-label>Habilitação Radar</mat-label>
    <mat-select [formControl]="habilitadoRadarFormControl" [(ngModel)]="habilitadoRadar" name="operadorLogico">
      <mat-option value="N">Não Possui</mat-option>
      <mat-option value="S">Suspensa</mat-option>
      <mat-option value="E">Expressa</mat-option>
      <mat-option value="L">Limitada</mat-option>
      <mat-option value="I">Ilimitada</mat-option>
    </mat-select>

    <mat-error *ngIf="habilitadoRadarFormControl.hasError('required')">
      Selecione um tipo.
    </mat-error>

  </mat-form-field>
  <h2>Score Total: {{score + scoreRadar}}</h2>
  <h2 *ngIf="compliance == false">Limite Financeiro: {{limiteFinanceiro | currency:'$'}}</h2>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="matrizAvaliada == false && isTouched == true">
  <button mat-button [mat-dialog-close]="true" (click)="putAvaliaMatrizItem(id, false, compliance, habilitadoRadar)">Reprovar</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="putAvaliaMatrizItem(id, true, compliance, habilitadoRadar)">Aprovar</button>
</mat-dialog-actions>
