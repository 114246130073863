<div class="card mt-3 mb-3 shadow-sm">
    <div class="card-body">
        <h3>Operações</h3>
        <form [formGroup]="form">

            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <input autofocus maxlength="50" formControlName="contrato" type="text" class="form-control mb-2"
                        placeholder="Buscar por contrato" />
                </div>
                <div class="col-md-4 col-sm-12">
                    <input autofocus maxlength="50" formControlName="boletoChange" type="text" class="form-control mb-2"
                        placeholder="Buscar por boleto" />
                </div>
                <div class="col-md-4 col-sm-12">
                    <input autofocus maxlength="200" formControlName="pagadorRecebedor" type="text"
                        class="form-control mb-2" placeholder="Buscar por beneficiário" />
                </div>
            </div>
            <div class="row">
                <div class="col col-md-4 col-sm-12">
                    <input autofocus maxlength="10" formControlName="dataInicioOperacao" type="text"
                        class="form-control mb-2" placeholder="Buscar por data de operação (De:)"
                        onfocus="(this.type='date')" onblur="(this.type= this.value ? 'date' : 'text')" />
                </div>
                <div class="col col-md-4 col-sm-12">
                    <input autofocus maxlength="10" formControlName="dataFimOperacao" type="text"
                        class="form-control mb-2" placeholder="Buscar por data de operação (Até:)"
                        onfocus="(this.type='date')" onblur="(this.type= this.value ? 'date' : 'text')" />
                </div>
                <div class="col  col-sm-12 col-md-4">
                    <select formControlName="tipoContratoId" class="form-select mb-2" id="tipoContratoId">
                        <option value="">Buscar por tipo de contrato</option>
                        <option *ngFor="let t of tiposDeContrato" [ngValue]="t.id">
                            {{ t.nome }}
                        </option>
                    </select>
                </div>

                <div class="col col-md-8 col-sm-12">
                    <input [ngModelOptions]="{standalone: true}" [(ngModel)]="clienteSearch" [typeahead]="clienteSuggestions$"
                    (typeaheadLoading)="clienteChangeTypeaheadLoading($event)"
                    (typeaheadOnSelect)="clienteTypeaheadOnSelect($event)" (typeaheadOnBlur)="clienteTypeaheadOnBlur($event)"
                    [typeaheadWaitMs]="300" [typeaheadOptionsLimit]="7" [typeaheadOptionField]="'nome'" placeholder="Buscar por cliente: Pesquise por nome, e-mail, cpnj ou cpf"
                    class="form-control">
                </div>

                <div class="col col-sm-12 col-md-4 text-end">
                    <button (click)="search()" class="btn btn-secondary"><i class="fa fa-search" aria-hidden="true"></i>
                        Buscar</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="operacoes.length==0" class="alert alert-primary mt-3" role="alert">
    Nenhum registro encontrado
</div>
<div class="table-responsive">
    <table *ngIf="operacoes.length>0" class="table table-bordered table-hover bg-white">
        <thead>
            <tr>
                <th>Operação</th>
                <th>Liquidação</th>
                <th>Cliente</th>
                <th>Contrato</th>
                <th>Beneficiário</th>
                <th>MN</th>
                <th>ME</th>
                <!--<th></th>-->
                <th>Contrato</th>
                <th>Swift</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of operacoes">
                <td>{{item.dataOperacao | date:'dd/MM/yyyy'}}</td>
                <td>{{item.dataLiquidacao | date:'dd/MM/yyyy'}}</td>
                <td>{{item.cliente.nome}}</td>
                <td>{{item.contrato}}</td>
                <td>{{item.pagadorRecebedor}}</td>
                <td>{{item.moedaNacional | currency : 'BRL'}}</td>
                <td>{{item.moedaEstrangeira | currency : item.moeda.nome}}</td>
                <!--<td>
                    <a [routerLink]="[ 'detalhe', item.id ]" class="btn btn-light btn-sm">
                        <i class="fa fa-calculator" aria-hidden="true"></i></a>
                </td>-->
                <td class="text-center">
                    <a *ngIf="item.arquivoContrato" [href]="item.urlContrato" class="btn btn-light btn-sm" title="Download Contrato" [download]="item.arquivoContrato">
                        <i class="fa fa-download" aria-hidden="true"></i>
                    </a>
                    &nbsp;
                    <a class="btn btn-light btn-sm" title="Upload Contrato" (click)="upload(item,true)">
                        <i class="fa fa-upload" aria-hidden="true"></i>
                    </a>
                </td>
                <td class="text-center">
                    <a *ngIf="item.arquivoSwift" [href]="item.urlSwift" class="btn btn-light btn-sm" title="Download Swift" [download]="item.arquivoSwift">
                        <i class="fa fa-download" aria-hidden="true"></i>
                    </a>
                    &nbsp;
                    <a class="btn btn-light btn-sm" title="Upload Swift" (click)="upload(item,false)">
                        <i class="fa fa-upload" aria-hidden="true"></i>
                    </a> 
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="row">
    <div class="col-lg-12">
        <pagination *ngIf="totalItems>itemsPerPage" [totalItems]="totalItems" [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)" [itemsPerPage]="itemsPerPage" [maxSize]="maxSize"></pagination>
    </div>
</div>