import { Component, OnInit } from '@angular/core';
import { CotacaoService } from 'src/app/services/cotacao.service';

@Component({
  selector: 'app-cotacao-vigente-page',
  templateUrl: './cotacao-manual-page.component.html',
  styleUrls: ['./cotacao-manual-page.component.scss']
})

export class CotacoesManualPageComponent implements OnInit {

  public cotacoesVigente: any[] = [];

  constructor(
    private cotacaoService: CotacaoService) {

  }

  ngOnInit(): void {
    this.list();
  }

  list() {
    this.cotacaoService.obterCotacoesVigenteOffLine()
      .subscribe((result: any) => {
        this.cotacoesVigente = result;
      });
  }

  saveValorCotacao(item: any) {
    debugger;
    let data = {
      id: item.id,
      valorCotacao: item.valorCotacao
    };

    this.cotacaoService.salvarCotacaoVigente(data).subscribe((data: any) => {
      this.list();
    }, (err) => {

    });
  }
}

